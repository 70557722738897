import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, FlatList, ActivityIndicator, StyleSheet, RefreshControl } from 'react-native';
import { commonStyle } from '../components/style'
import { useTranslation } from 'react-i18next';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { getLetters } from '../api/apis';
import { navigateRequireLogin } from '../components/util';

const Message = (props) => {

    const { t } = useTranslation();
    const navigation = useNavigation();

    const [messageData, setMessageData] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [page, setPage] = useState(1);

    if (props.route.params.type == 1) {
        console.log('系统公告')
    } else {
        console.log('客服消息')
    }

    React.useLayoutEffect(() => {
        navigation.setOptions({
            title: props.route.params.title,
            headerStyle: {
                backgroundColor: commonStyle.bgColor,
                borderBottomWidth: 0,
                height:30
            },
            headerTitleStyle: {
            },
        });
    }, [navigation]);

    useEffect(() => {
        handleRefresh()
    }, []);

    useFocusEffect(
        React.useCallback(() => {

        }, [])
    );

    // 刷新
    const handleRefresh = () => {
        setRefreshing(true);
        setPage(1)
        getFetch()
    };

    const getFetch = () => {
        setTimeout(() => {
            if (props.route.params.type == 1) {
                getAnnouncement()
            } else {
                getNotification()
            }
        }, 1000);
    }

    // 加载更多数据
    const handleLoadMore = () => {
        // console.log('更多...', page)
        setPage(parseInt(page) + 1)
        setLoadingMore(true);
        getFetch()
    };

    // 公告
    const getAnnouncement = () => {
        getLetters(1, 1).then(response => {
            setRefreshing(false);
            setLoadingMore(false);
            console.log(response)
            if (response.status == '1') {
                const updateData = response.Data.map(data => {
                    const time_year = new Date(parseInt(data.time_year) * 1000).toLocaleString();
                    return { ...data, time_year: time_year };
                });
                const dataList = messageData.concat(updateData)
                if (page > 1) {
                    setMessageData(dataList)
                } else {
                    setMessageData(updateData)
                }
            }
        }).catch(error => {
            setRefreshing(false);
            setLoadingMore(false);
            // console.error(error);
        });
    }

    // 通知
    const getNotification = () => {
        getLetters(1).then(response => {
            setRefreshing(false);
            setLoadingMore(false);
            console.log(response)
            if (response.status == '1') {
                const updateData = response.Data.data.map(data => {
                    const send_time = new Date(parseInt(data.send_time) * 1000).toLocaleString();
                    console.log(data.send_time, send_time)
                    return { ...data, send_time: send_time };
                });

                // const updateData = [
                //     {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"}, {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"},
                //     {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"}, {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"},
                //     {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"}, {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"},
                //     {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"}, {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"},
                //     {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"}, {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"},
                //     {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"}, {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"},
                //     {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"}, {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"},
                //     {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"}, {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"},
                //     {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"}, {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"},
                //     {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"}, {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"},
                //     {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"}, {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"},
                //     {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"}, {"id": "16966752592697791755259", "send_time": "2023/10/7 18:40:59", "sender": "客服", "status": "1", "title": "Thông báo rút tiền"},
                // ]

                const dataList = messageData.concat(updateData)

                console.log(dataList)

                if (page > 1) {
                    setMessageData(dataList)
                } else {
                    setMessageData(updateData)
                }
            }
        }).catch(error => {
            // console.error(error);
        });
    }

    const renderItem = ({ item }) => (
        <TouchableOpacity onPress={() => navigateRequireLogin(navigation, { name: 'MessageDetail', params: { id: item.id, type: props.route.params.type } })}>
            <View style={styles.itemContainer}>
                <View style={styles.itemRowContainer}>
                    <View><Text style={styles.itemTextGreen}>{item.title}</Text></View>

                </View>
                <View style={styles.itemRowContainer}>
                    <View><Text style={styles.itemTextGray}>{item.time_year}{item.send_time}</Text></View>
                    {props.route.params.type == 2 && item.status == 1 &&
                        <View style={{ width: 8, height: 8, backgroundColor: '#e54142', borderRadius: 10, borderWidth: 1, marginTop: -5 }}></View>
                    }
                </View>
            </View>
        </TouchableOpacity>
    );

    return (
        <View style={styles.container}>
            <View style={{ marginHorizontal: 10, height: '100%' }}>
                <FlatList
                    data={messageData}
                    renderItem={renderItem}
                    keyExtractor={(item, index) => index}
                    refreshControl={<RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />}
                    onEndReached={!loadingMore && handleLoadMore}
                    onEndReachedThreshold={0.1}
                    ListFooterComponent={loadingMore && <ActivityIndicator color={commonStyle.green} />}
                />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#1B1D29',
        // height: '99%'
    },
    tabContainer: {
        flexDirection: 'row',
        // paddingVertical: 10,
        backgroundColor: '#1B1D29',
        marginVertical: 10
    },
    tabItem: {
        flex: 1,
        alignItems: 'center',
        // paddingVertical: 10,
    },
    tabText: {
        color: commonStyle.textWhiteColor,
        fontSize: 17,
    },
    itemTextGreen: {
        color: commonStyle.textGreenColor,
    },
    itemTextGray: {
        color: commonStyle.textGrayColor
    },
    itemContainer: {
        // flexDirection: "column",
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderBottomWidth: 1,
        backgroundColor: commonStyle.black,
        marginTop: 10,
        // width: '90%',
        borderRadius: 5,
        // margin: 'auto'
    },
    itemRowContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 4
    },
    center: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    rechargeButton: {
        flexDirection: 'row',
        backgroundColor: commonStyle.green,
        justifyContent: 'center',
        alignItems: 'center',
        height: 35,
        // width: '25%',
        paddingHorizontal: 15,
        marginTop: 20,
        borderRadius: 20,
        // margin: 'auto',
        marginBottom: 20
    },
    whileText: {
        color: commonStyle.textWhiteColor
    },
    containerHeader: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: commonStyle.black,
        height: 140
    }
});

export default Message;