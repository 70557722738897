import React, { useState, useRef } from 'react';
import { StyleSheet, View, Text, TextInput, TouchableOpacity } from 'react-native';
import { useTranslation } from 'react-i18next';
import SpliteLine from '../components/spliteLine'
import { commonStyle } from '../components/style'
import Toast from 'react-native-easy-toast'
import { getSetpassswd } from '../api/apis';
import { useNavigation } from '@react-navigation/native';

const EditPassword = () => {
    const { t } = useTranslation();
    const toastRef = useRef(null);
    const [originalPassword, setOriginalPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const navigation = useNavigation();

    const handleOriginalPasswordChanged = text => {
        setOriginalPassword(text);
    };

    const handlePasswordChanged = text => {
        setPassword(text);
    };

    const handleConfirmPasswordChanged = text => {
        setConfirmPassword(text);
    };

    const handleConfirm = async () => {
        if (originalPassword === '') {
            toastRef.current.show(t('pleaseEnterOriginalPassword'));
            return;
        }
        if (password === '') {
            toastRef.current.show(t('pleaseEnterPassword'));
            return;
        }
        if (confirmPassword === '') {
            toastRef.current.show(t('pleaseEnterconfirmPassword'));
            return;
        }
        if (password !== confirmPassword) {
            toastRef.current.show(t('confirmPasswordInconsistency'));
            return;
        }

        const response = await getSetpassswd(originalPassword, password, confirmPassword);
        console.log(response);
        toastRef.current.show(response.Data.msg);
        if (response.status == '1') {
            navigation.goBack()
            return;
        }

    };

    return (
        <View style={styles.container}>
            <View style={styles.form}>

                <View>
                    <Text style={styles.label}>{t('originalPassword')}</Text>
                    <TextInput
                        style={styles.input}
                        secureTextEntry={true}
                        placeholder={t('pleaseEnterOriginalPassword')}
                        onChangeText={handleOriginalPasswordChanged}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                    />
                    <SpliteLine />
                </View>

                <View>
                    <Text style={styles.label}>{t('setPassword')}</Text>
                    <TextInput
                        style={styles.input}
                        secureTextEntry={true}
                        placeholder={t('pleaseEnterPassword')}
                        onChangeText={handlePasswordChanged}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                    />
                    <SpliteLine />
                </View>
                <View>
                    <Text style={styles.label}>{t('confirmPassword')}</Text>
                    <TextInput
                        style={styles.input}
                        secureTextEntry={true}
                        placeholder={t('pleaseEnterconfirmPassword')}
                        onChangeText={handleConfirmPasswordChanged}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"

                    />
                    <SpliteLine />
                </View>
                <TouchableOpacity style={styles.button} onPress={handleConfirm}>
                    <Text style={styles.buttonText}>{t('confirm')}</Text>
                </TouchableOpacity>
            </View>
            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={200}
                // fadeInDuration={750}
                // fadeOutDuration={1000}
                // opacity={0.8}
                textStyle={{ color: '#fff' }}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,  // 主轴上尺寸比例
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center', // 交叉轴对齐方式
        justifyContent: 'center',//主轴对齐方式
        // flexDirection:"column" //主轴方向
    },
    form: {
        width: '80%',
    },
    label: {
        color: '#fff',
        fontSize: 16,
        marginTop: 10,
    },
    input: {
        color: '#707070',
        marginBottom: 15,
        marginTop: 15,
        height: 30,
        borderWidth: 0,
        borderBottomColor: 'transparent',
    },
    inviteCodeInput: {
        flex: 2,
        width: 230,
    },
    inputContainer: {
        flexDirection: 'row',
    },
    requiredText: {
        flex: 1,
        color: '#fff',
        lineHeight: 45,
        textAlign: 'right',
        fontSize: 18,
    },
    button: {
        backgroundColor: '#2C3445',
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        marginTop: 40,
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
    },
});

export default EditPassword;