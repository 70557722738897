import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Text, ScrollView, Image, TouchableOpacity, Platform } from 'react-native';
import { commonStyle } from '../components/style';
import { SimpleLineIcons, Fontisto, AntDesign, Entypo ,MaterialIcons} from '@expo/vector-icons';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next'
import { userCenter, getWebsiteInfo } from '../api/apis';
import AsyncStorage from '@react-native-community/async-storage';
import SpliteLine from '../components/spliteLine'
import { use } from 'i18next';
import appJson from '../../app.json'; // 请根据 app.json 文件的路径进行相应的更改
import Constants from 'expo-constants';
import * as Updates from 'expo-updates';

const My = () => {

    const { i18n, t } = useTranslation();

    const [userInfo, setUserInfo] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [unreadMessage, setUnreadMessage] = useState(0); //未读消息
    const [depositsWithdrawals, setDepositsWithdrawals] = useState([0, 0]); //1_1 提现和充值显示

    const languages = {
        en: 'English',   //英语
        cn: '中文简体',   //中文
        es: 'Español',   //西班牙语
        pt: 'Português', //葡萄牙语
        vn: 'Tiếng Việt', //越南语
        id: 'Indonesia' //印尼语
    };

    useEffect(() => {
        init()
    }, []);

    useFocusEffect(
        React.useCallback(() => {
            // console.log('my在页面获得焦点时执行')
            isLoggedIn()
        }, [])
    );

    const isLoggedIn = async () => {
        const userinfo = await AsyncStorage.getItem('userinfo');
        const cookies = await AsyncStorage.getItem('cookies');
        if (userinfo) {
            setUserInfo(JSON.parse(userinfo))
            setIsLogin(true)
        } else {
            setUserInfo(false)
            setIsLogin(false)
            // navigation.navigate('Login');
        }

        // website  deposits_withdrawals  1_1 提现和充值显示
        const websiteInfo = await getWebsiteInfo();
        if (websiteInfo.status == '1') {
            setDepositsWithdrawals(websiteInfo.Data.deposits_withdrawals.split('_'))
        }
    }

    const init = async () => {
        const version = appJson.expo.version;
        console.log('当前版本', version);

        userCenter().then(response => {
            if (response.status == 1) {
                const data = response.Data1.item[0]
                setUnreadMessage(data.status)
            }
        }).catch(error => {
            // console.error(error);
        });
    };

    const navigation = useNavigation();

    const goToLanguageScreen = () => {
        navigation.navigate('Language');
    }

    const goToCustomerScreen = () => {
        navigation.navigate('Customer');
    }

    const goToLoginScreen = () => {
        navigation.navigate('Login');
    }

    const goToScreen = (route) => {
        if (!isLogin) {
            goToLoginScreen()
            return
        }
        navigation.navigate(route);
    }

    async function onFetchUpdateAsync() {
        try {
            const update = await Updates.checkForUpdateAsync();
            if (update.isAvailable) {
                alert(t('waitingForUpdates'))
                await Updates.fetchUpdateAsync();
                await Updates.reloadAsync();
            } else {
                // await Updates.fetchUpdateAsync();
                alert(t('noUpdates'))
            }
        } catch (error) {
            // You can also add an alert() to see the error message in case of an error when fetching updates.
            alert(`${error}`);
            console.log(`${error}`);
        }
    }

    return (
        <View style={styles.container}>
            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                style={{ width: '90%' }}
            >
                <View style={[styles.section, { marginTop: 10 }]}>
                    <Text style={styles.title}>{t('me')}</Text>
                    <TouchableOpacity style={styles.iconBadge} onPress={() => { goToScreen('MessageNotifications') }}>
                        <SimpleLineIcons name="bell" size={22} color="#FFF" />
                        {/* {unreadMessage > 0 && <View style={{ width: 14, height: 14, backgroundColor: '#e54142', borderRadius: 10, position: 'relative', top: -28 }}>
                            <Text style={{ color: '#fff', textAlign: 'center', fontSize: 10 }}>{unreadMessage > 99 ? 99 : unreadMessage}</Text>
                        </View>} */}
                    </TouchableOpacity>
                </View>
                <View style={styles.sectionContainer}>
                    <View style={[styles.section, { width: '95%', margin: 'auto', marginBottom: 15 }]}>
                        {userInfo ? (
                            <Text style={styles.name}>{userInfo.user_name ? userInfo.user_name : userInfo.user_account_number}</Text>
                        ) : (
                            <TouchableOpacity onPress={goToLoginScreen}>
                                <Text style={[styles.name, { marginLeft: 15 }]}>{t('clickLogin')}</Text>
                            </TouchableOpacity>
                        )}
                        <View style={styles.switchSection}>
                            <Text style={styles.country}> {languages[i18n.language]}【{i18n.language.toUpperCase()}】</Text>
                            <View style={styles.switch} >
                                <TouchableOpacity style={styles.icon} onPress={goToLanguageScreen}>
                                    <AntDesign name="swap" size={20} color="#00FF9B" />
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                    <View style={[styles.section, {}]}>
                        <View style={[styles.iconText, styles.flexColumn]}>
                            <TouchableOpacity style={styles.icon} onPress={() => { goToScreen('Wallet') }}>
                                <Image style={styles.image} source={require('../../assets/imgs/my/billfold.png')} />
                            </TouchableOpacity>
                            <Text style={styles.labelTop}>{t('wallet')}</Text>
                        </View>

                        {/* 充值、提现调用api参数是否显示 */}
                        {depositsWithdrawals[1] == 1 && <View style={[styles.iconText, styles.flexColumn]}>
                            <TouchableOpacity style={styles.icon} onPress={() => { goToScreen('Balance') }}>
                                <Image style={styles.image} source={require('../../assets/imgs/my/recharge.png')} />
                            </TouchableOpacity>
                            <Text style={styles.labelTop}>{t('recharge')}</Text>
                        </View>}
                        {depositsWithdrawals[0] == 1 &&
                            <View style={[styles.iconText, styles.flexColumn]}>
                                <TouchableOpacity style={styles.icon} onPress={() => { goToScreen('ExtractCurrency') }}>
                                    <Image style={styles.image} source={require('../../assets/imgs/my/withdrawal.png')} />
                                </TouchableOpacity>
                                <Text style={styles.labelTop}>{t('withdrawal')}</Text>
                            </View>
                        }

                        <View style={[styles.iconText, styles.flexColumn]}>
                            <TouchableOpacity style={styles.icon} onPress={goToCustomerScreen}>
                                <Image style={styles.image} source={require('../../assets/imgs/my/customer.png')} />
                            </TouchableOpacity>
                            <Text style={styles.labelTop}>{t('customer')}</Text>
                        </View>
                    </View>
                </View>
                <View style={[styles.sectionContainer, { paddingVertical: 10 }]}>
                    <TouchableOpacity onPress={() => { goToScreen('Publicize'); }}>
                        <View style={styles.section}>
                            <View style={styles.iconText}>
                                <View style={styles.icon}>
                                    <Image style={styles.image2} source={require('../../assets/imgs/my/share.png')} />
                                </View>
                                <Text style={styles.label}>{t('shareEarn')}</Text>
                            </View>
                            <View style={styles.iconText}>
                                {/* <Text style={styles.subText}>{t('successfulSharing')} 10BTC</Text> */}
                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                        <SpliteLine style={{ marginTop: 5, marginBottom: 5 }} />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => { goToScreen('PaymentMethod'); }}>
                        <View style={[styles.section, {}]}>
                            <View style={styles.iconText}>
                                <View style={styles.icon}>
                                    <Image style={styles.image2} source={require('../../assets/imgs/my/pay.png')} />
                                </View>
                                <Text style={styles.label}>{t('paymentOptions')}</Text>
                            </View>
                            <View style={styles.iconText}>
                                {/* <Text style={styles.subText}>{t('bankCard')}</Text> */}
                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                        <SpliteLine style={{ marginTop: 5, marginBottom: 5 }} />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => { goToScreen('FinancialPassword'); }}>
                        <View style={styles.section}>
                            <View style={styles.iconText}>
                                <View style={styles.icon}>
                                    <Image style={styles.image2} source={require('../../assets/imgs/my/fund_password.png')} />
                                </View>
                                <Text style={styles.label}>{t('financialPassword')}</Text>
                            </View>
                            <View style={styles.iconText}>
                                {/* <Text style={styles.subText}>{t('notSet')}</Text> */}
                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                    </TouchableOpacity>
                </View>
                <View style={styles.sectionContainer2}>

                    <TouchableOpacity onPress={() => { goToScreen('Settings'); }}>
                        <View style={styles.section}>
                            <View style={styles.iconText}>
                                <View style={styles.icon}>
                                    <Fontisto name="player-settings" size={22} color={commonStyle.textGreyColor} style={{ marginHorizontal: 6 }} />
                                </View>
                                <Text style={styles.label}>{t('settings')}</Text>
                            </View>
                            <View style={styles.iconText}>
                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                    </TouchableOpacity>

                    {Platform.OS !== 'web' && <TouchableOpacity onPress={onFetchUpdateAsync}>
                        <SpliteLine style={{ marginTop: 10, marginBottom: 10 }} />
                        <View style={styles.section}>
                            <View style={styles.iconText}>
                                <View style={styles.icon}>
                                    <MaterialIcons name="system-update" size={22} color={commonStyle.textGreyColor} style={{ marginHorizontal: 6 }} />
                                </View>
                                <Text style={styles.label}>{t('checkForUpdates')}</Text>
                            </View>
                            <View style={styles.iconText}>
                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                    </TouchableOpacity>}


                    {/* <TouchableOpacity onPress={() => { navigation.navigate('ArticleDetail'); }}>
                        <View style={styles.section}>
                            <View style={styles.iconText}>
                                <View style={styles.icon}>
                                    <Entypo name="help-with-circle" size={20} color={commonStyle.textGreyColor} style={{ marginRight: 5 }} />
                                </View>
                                <Text style={styles.label}>{t('helpCenter')}</Text>
                            </View>
                            <View style={styles.iconText}>
                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={() => { navigation.navigate('ArticleDetail'); }}>
                        <View style={styles.section}>
                            <View style={styles.iconText}>
                                <View style={styles.icon}>
                                    <AntDesign name="message1" size={20} color={commonStyle.textGreyColor} style={{ marginRight: 5 }} />
                                </View>
                                <Text style={styles.label}>{t('contactUs')}</Text>
                            </View>
                            <View style={styles.iconText}>
                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                    </TouchableOpacity> */}

                </View>
                <Text style={{ textAlign: 'center', color: '#1a1a1a' }}>v{appJson.expo.version}</Text>
            </ScrollView>

        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center',
        justifyContent: 'center',
        margin: 0,
    },
    sectionContainer: {
        backgroundColor: '#222430',
        marginBottom: 20,
        borderRadius: 5,
        paddingHorizontal: 10,
        paddingVertical: 20,
    },
    sectionContainer2: {
        backgroundColor: '#222430',
        // marginBottom: 20,
        borderRadius: 5,
        paddingHorizontal: 10,
        paddingVertical: 15,
    },
    section: {
        // marginBottom: 15,
        flexDirection: "row",
        justifyContent: 'space-between',
        // marginVertical: 2
    },
    title: {
        fontSize: 16,
        // fontWeight: 'bold',
        marginBottom: 10,
        color: commonStyle.textWhiteColor
    },
    iconBadge: {
        fontSize: 16,
        // fontWeight: 'bold',
    },
    name: {
        fontSize: 20,
        // fontWeight: 'bold',
        marginBottom: 10,
        color: commonStyle.textWhiteColor
    },
    labelTop: {
        color: commonStyle.textWhiteColor,
        marginTop: 2
    },
    switchSection: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    country: {
        // marginRight: 2,
        color: '#828B9F'
    },
    switch: {
        // backgroundColor: '#ccc',
        // padding: 5,
        // borderRadius: 5,
        // color: '#828B9F'
    },
    iconText: {
        flexDirection: 'row',
        alignItems: 'center',
        // marginBottom: 10,
        color: '#828B9F',
    },
    icon: {
        // backgroundColor: '#ccc',
        // width: 30,
        // height: 30,
        // marginRight: 10,
        // alignItems: 'center',
        // justifyContent: 'center',
    },
    label: {
        fontSize: 15,
        color: '#828B9F',
        position: 'relative',
        // lineHeight:38
        // color: commonStyle.textWhiteColor
    },
    subText: {
        fontSize: 14,
        marginRight: 10,
        color: '#828B9F'
    },
    goArrow: {
        fontSize: 20,
        // fontWeight: 'bold',
    },
    iconInner: {
        backgroundColor: '#ccc',
        width: 20,
        height: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        width: 30,
        height: 30
    },
    image2: {
        width: 35,
        height: 35
    },
    flexColumn: {
        flexDirection: 'column',
        flex: 1
    }
});

export default My;