import { Platform } from 'react-native';
import axios from 'axios';
import AsyncStorage from '@react-native-community/async-storage';
import { useNavigation } from '@react-navigation/native';
import config from '../../config'; // 在开发环境下导入开发配置

// 域名列表，包括备用域名
const domains = config.apiUrl;


// 存储当前域名的索引
let currentDomainIndex = -1;

// const baseURL = 'http://local.api.com/proxy.php' //本地代理域名

const lang = {
  en: 'en-US',
  cn: 'zh-CN',
  es: 'es', //西班牙语
  pt: 'pt', //葡萄牙语
  vn: 'vi', //越南语
  id: 'id-ID' //越南语
};

// 存储数据
const storeData = async (key, value) => {
  try {
    await AsyncStorage.setItem(key, value);
    // console.log('数据已存储');
  } catch (error) {
    // console.log('存储数据时出错:', error);
  }
};



const instance = axios.create({
  // baseURL,
  timeout: 10000,
  withCredentials: true // 允许跨域请求带cookie
});


if(Platform.OS == 'web'){
  if (config.env == 'local') {
    instance.defaults.baseURL = domains[0];
  }else{
    instance.defaults.baseURL = '';
  }
}


const removeItem = async () => {
  await AsyncStorage.removeItem('userinfo');
  await AsyncStorage.removeItem('cookies');
};

instance.interceptors.response.use(
  (response) => {

    const { data } = response;

    // console.log('返回', response.status,response.request.responseURL)
    // console.log('返回信息')
    // console.log(data)

    // console.log('baseurl', baseURL)

    // console.log(response)

    const cookies = response.headers['set-cookie'];
    if (cookies != undefined) {
      storeData('cookies', JSON.stringify(cookies))
    }

    // console.log('set-cookie')
    // console.log(cookies)

    if (data.status == 0) {
      const code = data.Data?.Code || data.Data1?.Code;
      // console.log('code:', code)
      switch (code) {
        case '-5':
          console.log('没有余额');
          break;
        case '-8':
          console.log('请登录');
          removeItem();
          useNavigation().navigate('Login');
          break;
        case '-10':
          console.log('去维护页面');
          break;
        case '-13':
          console.log('域名未绑定平台');
          break;
        default:
          console.log('未知错误', code);
      }
    }

    return data;
  },
  (error) => {

    console.log(error)

    // 如果需要切换到下一个域名
    if (Platform.OS != 'web') {
      if (currentDomainIndex < domains.length - 1) {
        currentDomainIndex++; // 移动到下一个域名
        instance.defaults.baseURL = domains[currentDomainIndex]; // 切换到下一个域名
        return instance(error.config); // 重新发送请求
      }

      if (error.response) {
        const { status, data } = error.response;
        return Promise.reject({ status: status, Data: { Code: -1, msg: 'error' } })
        // return Promise.reject({ code: status, message: data.message });
      } else if (error.request) {
        return Promise.reject({ status: -1, Data: { Code: -1, msg: '网络连接错误，请检查网络设置！' } })
        // return Promise.reject({ code: -1, message: '网络连接错误，请检查网络设置！' });
      } else {
        return Promise.reject({ status: -1, Data: { Code: -1, msg: '发生错误：' + error.message } })
        // return Promise.reject({ code: -1, message: '发生错误：' + error.message });
      }

    }

  }
);

const isNew = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (t) {
    const e = (16 * Math.random()) | 0;
    const a = t === 'x' ? e : (3 & e) | 8;
    return a.toString(16);
  });
}

instance.interceptors.request.use(
  async (config) => {
    const cookieStr = await AsyncStorage.getItem('cookies');
    // console.log('cookie请求头')
    // console.log(cookieStr)
    if (cookieStr && cookieStr.length > 0) {
      const cookies = JSON.parse(cookieStr);
      config.headers.Cookie = cookies[0];
    }

    const language = await AsyncStorage.getItem('language');
    if (language) {
      config.headers['Accept-Language'] = lang[language];
    }

    config.data = {
      ...config.data,
      isNew: isNew(),
    };

    // console.log('请求信息')
    // console.log(config)

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;