import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Button } from 'react-native';
import { commonStyle } from '../components/style';
import { useTranslation } from 'react-i18next'

const Language = () => {

    const { i18n } = useTranslation();

    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    const languages = [
        { key: 'en', value: 'English' },
        { key: 'cn', value: '中文简体' },
        { key: 'es', value: 'Español' }, //西班牙语
        { key: 'pt', value: 'Português' }, //葡萄牙语
        { key: 'vn', value: 'Tiếng Việt' },  //越南语
        { key: 'id', value: 'Indonesia' }  //越南语
    ];

    const handleLanguageSelect = (language) => {
        setSelectedLanguage(language);
        i18n.changeLanguage(language)
    };

    return (
        <View style={styles.container}>
            <View style={{marginTop:20}}></View>
            {languages.map((language, index) => (
                <TouchableOpacity
                    key={index}
                    onPress={() => handleLanguageSelect(language.key)}
                    style={[
                        styles.languageItem,
                        selectedLanguage === language.key && styles.selectedLanguage,
                    ]}
                >
                    <Text
                        style={[
                            styles.languageText,
                            selectedLanguage === language.key && styles.selectedText,
                        ]}
                    >
                        {language.value}
                    </Text>

                </TouchableOpacity>
            ))}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        alignItems: 'flex-start',
        // justifyContent: 'center',
        paddingHorizontal: 20,
    },
    languageItem: {
        marginBottom: 20,
    },
    //   selectedLanguage: {
    //     backgroundColor: '#00FF00',
    //     borderRadius: 5,
    //   },
    languageText: {
        color: '#828B9F',
        fontSize: 16,
    },
    selectedText: {
        color: '#13B777',
    },
});

export default Language;