import api from './baseApi';
import AsyncStorage from '@react-native-community/async-storage';
import { virtual } from '../components/util';

const defaultHeaders = {
    'Content-Type': 'application/x-www-form-urlencoded',
};

// async function getLanguages() {
//     const value = await AsyncStorage.getItem('language');
//     return languages[value];
// }

// async function getCookies() {
//     const cookieStr = await AsyncStorage.getItem('cookies');
//     return JSON.parse(cookieStr);
// }

function generateToken() {
    const chars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
    let token = "";
    for (let i = 0; i < 82; i++) {
        const b = Math.ceil(Math.random() * 35);
        token += chars[b];
    }
    return token;
}

// 网站信息
async function getWebsiteInfo() {
    const l = await AsyncStorage.getItem('websiteInfo');
    const lj = JSON.parse(l);
    const formData = {
        // cache: lj?.cache || "",
    };
    // console.log('formdata')
    // console.log(formData)

    const headers = {
        ...defaultHeaders,
    };

    const response = await api.post('/api/websiteInfo', formData, {
        headers: headers,
    });
    return response;
}

// 图形验证码
async function getImageCode(imageCodeKey, t) {
    const formData = {
        v: imageCodeKey,
        t: t,
    };

    const headers = {
        ...defaultHeaders,
    };

    const response = await api.post('/api/getimagecode', formData, {
        headers: headers,

    });
    return response;
}

// 登录
async function login(username, password, imageCode, imageCodeKey, customHeaders = {}) {
    const formData = {
        u: username,
        p: password,
        ImgCode: imageCode,
        ImgCode_key: imageCodeKey,
    };
    const headers = {
        ...defaultHeaders,
        ...customHeaders,
    };
    const response = await api.post('/api/login', formData, { headers });
    return response;
}

// 注册
async function register(username, password, inviteCode, imageCode, imageCodeKey, customHeaders = {}) {
    const formData = {
        UserName: username,
        Password: password,
        user_upper_level: inviteCode, //邀请码
        currency: 'USD', // 默认美元
        ImgCode: imageCode,
        ImgCode_key: imageCodeKey
    };
    const headers = {
        ...defaultHeaders,
    };
    const response = await api.post('/api/registertest', formData, {
        headers: headers,
        ...customHeaders,
    });
    return response;
}

// 个人中心
async function userCenter(customHeaders = {}) {
    const headers = {
        ...defaultHeaders,
        ...customHeaders,
    };
    const response = await api.post('/api/usercenter', {}, { headers });
    return response;
}

// 提款信息获取
async function getWithdraw(customHeaders = {}) {
    const headers = {
        ...defaultHeaders,
        ...customHeaders,
    };
    const response = await api.post('/api/getwithdraw', {}, { headers });
    return response;
}

// 提款
// {
//     "status": 1,
//     "msg": "提款申请已经提交，请在2小时后查询是否到账",
//     "Data": []
// }
async function getwithdrawDetermine(params, customHeaders = {}) {
    const formData = {
        data: params
    };
    const headers = {
        ...defaultHeaders,
        ...customHeaders,
    };
    console.log(formData)
    const response = await api.post('/api/getwithdraw_determine', formData, { headers });
    return response;
}

// 忘记密码
async function findPassword(username, imageCode, imageCodeKey, customHeaders = {}) {
    const formData = {
        u: username,
        ImgCode: imageCode,
        ImgCode_key: imageCodeKey,
    };
    const headers = {
        ...defaultHeaders,
        ...customHeaders,
    };
    const response = await api.post('/api/find_password', formData, { headers });
    return response;
}

// 修改密码
async function getSetpassswd(old_pass, new_passs_1, new_passs_2, customHeaders = {}) {
    const formData = {
        data: `{ "old_pass": ${old_pass}, "new_passs_1": ${new_passs_1}, "new_passs_2": ${new_passs_2}, "resetWayKey": "", "resetWayKeyuser": "" }`
    };
    const headers = {
        ...defaultHeaders,
        ...customHeaders,
    };
    const response = await api.post('/api/getsetpassswd', formData, { headers });
    return response;
}

// 推广
async function getPromotionTest(params = {}, customHeaders = {}) {
    const headers = {
        ...defaultHeaders,
        ...customHeaders,
    };
    console.log('推广请求参数')
    console.log(params)
    const response = await api.post('/api/get_promotiontest', params, { headers });
    return response;
}

// 资金密码
async function verifysafepwd(password, confirmPassword, customHeaders = {}) {
    const formData = {
        data: `{"SafePwd1":${password},"SafePwd2":${confirmPassword},"oldSafePwd":""}`
    };
    const headers = {
        ...defaultHeaders,
        ...customHeaders,
    };
    const response = await api.post('/api/verifysafepwd', formData, { headers });
    return response;
}

// 安全密保问题
async function getsetquestion(question1, question2, question3, answer1, answer2, answer3, customHeaders = {}) {
    const formData = {
        data: `{"question1":"comm.601","question2":"comm.602","question3":"你的初中叫什么名字？" ,"answer1":"1","answer2":"2","answer3":"3","username":"","operating_state":"1"}`
    };
    const headers = {
        ...defaultHeaders,
        ...customHeaders,
    };
    const response = await api.post('/api/verifysafepwd', formData, { headers });
    return response;
}

// 充值信息
async function getRechargeTron(customHeaders = {}) {
    const formData = {
        operating_state: 1,
        curr: 'USD',
    };
    const headers = {
        ...defaultHeaders,
        ...customHeaders,
    };
    const response = await api.post('/api/get_rechargetron', formData, { headers });
    return response;
}

// 充值
async function getRecharge(params, customHeaders = {}) {
    const formData = {
        operating_state: 2,
        data: params,
    };
    const headers = {
        ...defaultHeaders,
        ...customHeaders,
    };
    const response = await api.post('/api/get_recharge', formData, { headers });
    return response;
}


// 银行卡详情
async function getcarddetail(id) {
    const formData = {
        data: `{"BankCardID":"${id}"}`,
    };
    console.log(formData)
    const headers = {
        ...defaultHeaders,
    };
    const response = await api.post('/api/getcarddetail', formData, { headers });
    return response;
}

// 编辑银行卡
async function addbankcard(params) {
    const formData = {
        data: params,
    };
    const headers = {
        ...defaultHeaders,
    };
    const response = await api.post('/api/addbankcard', formData, { headers });
    return response;
}

// 银行卡
async function checkbankcard(customHeaders = {}) {
    const formData = {
        data: '11',
    };
    const headers = {
        ...defaultHeaders,
        ...customHeaders,
    };
    const response = await api.post('/api/checkbankcard', formData, { headers });
    return response;
}

// 用户余额 Data.BackData
async function getUserbalance() {
    const formData = {};
    const headers = {
        ...defaultHeaders,
    };
    const response = await api.post('/api/userbalance', formData, { headers });
    return response;
}

// 交易记录 type空账户明细 tx：提现 cz:充值
async function getTraderecord(page, type) {
    const formData = {
        data: `{"data_lottery_day":"7","data_lottery_num":"all","page":"${page}","type":"${type}"}`
    };
    const headers = {
        ...defaultHeaders,
    };
    console.log(formData)
    const response = await api.post('/api/traderecord', formData, { headers });
    return response;
}

// 通知公告
async function getLetters(page, type = "") {
    const formData = {};
    if (type != "") {
        formData.type = type //公告
    } else {
        formData.page = page //通知
    }
    console.log(formData)
    const headers = {
        ...defaultHeaders,
    };
    const response = await api.post('/api/get_letters', formData, { headers });
    return response;
}

// 公告详情
async function getAnnouncementdetail(id) {
    const formData = {
        data_id: id
    };
    const headers = {
        ...defaultHeaders,
    };
    const response = await api.post('/api/announcementdetail_get', formData, { headers });
    return response;
}

// 客服消息详情
async function getletterDetail(id) {
    const formData = {
        data_id: id
    };
    const headers = {
        ...defaultHeaders,
    };
    const response = await api.post('/api/letter_detail', formData, { headers });
    return response;
}

// 赔率
async function getrebate(lotteryType) {
    const formData = {
        LotteryType: lotteryType
    };
    const headers = {
        ...defaultHeaders,
    };
    const response = await api.post('/api/getrebate', formData, { headers });
    return response;
}

// 下单
async function addbetting(formData) {
    // const formData = {
    //     lottery_code: params.lacode,
    //     betting_number: params.cont,
    //     betting_money: params.amount,
    //     betting_count: params.sel_bet,
    //     play_detail_code: params.play_id,
    //     betting_issuseNo: params.no,
    // };

    console.log(formData)
    const headers = {
        ...defaultHeaders,
    };
    const response = await api.post('/api/addbetting', formData, { headers });
    return response;
}

// 投注下单记录
async function betcenterBetrecord(page) {
    const formData = {
        data: `{"data_lottery_day":"7","data_lottery_num":"all","page":"${page}"}`
    };
    const headers = {
        ...defaultHeaders,
    };
    const response = await api.post('/api/betcenter_betrecord', formData, { headers });
    return response;
}

async function servertime() {
    const formData = {

    };
    const headers = {
        ...defaultHeaders,
    };
    const response = await api.post('/api/servertime', formData, { headers });
    return response;
}


// 获取币种价格
async function getSymbolPrice(symbol) {
    try {
        const cleanSymbol = symbol.toLowerCase().trim();
        let url = '';
        let fieldName = '';

        const coinToIdMap = virtual()

        fieldName = coinToIdMap[symbol.toUpperCase().trim()];

        url = `https://api.coingecko.com/api/v3/simple/price?ids=${fieldName}&vs_currencies=usd`

        // console.log(url)

        const response = await fetch(url);
        if (response.status === 200) {
            const data = await response.json();
            if (data[fieldName] && data[fieldName].usd) {
                const price = data[fieldName].usd;
                // console.log(`${cleanSymbol.toUpperCase()}价格: ${price}`);
                return price;
            } else {
                // console.log(`获取${cleanSymbol.toUpperCase()}价格失败`);
                return false
            }
        } else {
            // console.log(`获取${cleanSymbol.toUpperCase()}价格失败: HTTP错误 ${response.status}`);
            return false
        }
    } catch (error) {
        // console.log(`获取价格时出错: ${error}`);
        return false
    }
}

// 火币获取价格
async function getHuobiPrice(symbol) {
    try {
        const cleanSymbol = symbol.toLowerCase().trim();
        const url = `https://api-aws.huobi.pro/market/trade?symbol=${cleanSymbol}usdt`
        // console.log(url)
        const response = await fetch(url);
        if (response.status === 200) {
            const data = await response.json();
            return data.tick.data[0].price
        } else {
            // console.log(`HTTP错误 ${response.status}`);
            return false
        }
    } catch (error) {
        // console.log(`出错: ${error}`);
        return false
    }
}

// K线图数据 4hour,60min,1day
async function getKline(symbol, interval, size) {
    try {
        const cleanSymbol = symbol.toLowerCase().trim();
        let url = `https://api-aws.huobi.pro/market/history/kline?symbol=${cleanSymbol}usdt&period=${interval}&size=${size}`;
        // console.log(url)
        const response = await fetch(url);
        if (response.status === 200) {
            const data = await response.json();
            const klineData = data.data
            let changePercentage = 0
            if (klineData.length >= 2) {
                const closePrev = parseFloat(klineData[0].close);
                const closeCurrent = parseFloat(klineData[1].close);
                const change = closeCurrent - closePrev;
                // changePercentage = (closePrev /  change  ) * 100;
                changePercentage = ((closePrev - closeCurrent) / closePrev) * 100

            }
            return { klineData: klineData, changePercentage: changePercentage.toFixed(2) }
        } else {
            // console.log(`HTTP错误 ${response.status}`);
            return false
        }
    } catch (error) {
        // console.log(`出错: ${error}`);
        return false
    }
}

// 币价格char数据
async function getMarketChart(symbol, interval = '') {
    try {
        const cleanSymbol = symbol.toLowerCase().trim();
        let url = '';
        let fieldName = '';

        const coinToIdMap = virtual()

        fieldName = coinToIdMap[symbol.toUpperCase().trim()];

        url = `https://api.coingecko.com/api/v3/coins/${fieldName}/market_chart?vs_currency=usd&days=1`
        if (interval != '' && interval != '1d') {
            url += '&Invalid=' + interval
        }

        // console.log(url)

        const response = await fetch(url);
        if (response.status === 200) {
            const data = await response.json();

            // 数据中的 volumes 字段包含24小时内的交易量数据
            const volumes = data.prices.map((entry) => entry[1]);

            // 计算总交易量
            const totalVolume = volumes.reduce((total, volume) => total + volume, 0);

            // 计算最高和最低价格
            const maxPrice = Math.max(...volumes);
            const minPrice = Math.min(...volumes);

            // 提取最后一条价格信息作为最新价格
            const latestPrice = data.prices[data.prices.length - 1][1];

            //百分比涨跌
            let priceChangePercent = 0
            if (data.prices.length >= 2) {
                const todayPrice = latestPrice;
                const yesterdayPrice = data.prices[0][1];
                priceChangePercent = ((todayPrice - yesterdayPrice) / yesterdayPrice) * 100;
                // console.log(symbol, priceChangePercent)
            }

            return {
                data: data.prices,
                priceChangePercent: priceChangePercent.toFixed(2),
                totalVolume: totalVolume.toFixed(2),
                maxPrice: maxPrice.toFixed(2),
                minPrice: minPrice.toFixed(2),
                latestPrice: latestPrice.toFixed(2),
            }
        } else {
            console.log(`获取${cleanSymbol.toUpperCase()}chart失败: HTTP错误 ${response.status}`);
            return false
        }
    } catch (error) {
        console.log(`获取chart时出错: ${error}`);
        return false
    }

}

export {
    generateToken,
    getWebsiteInfo,
    getImageCode,
    login,
    register,
    userCenter,
    getPromotionTest,
    getRechargeTron,
    getWithdraw,
    findPassword,
    getSetpassswd,
    verifysafepwd,
    getsetquestion,
    getUserbalance,
    getTraderecord,
    getLetters,
    getAnnouncementdetail,
    getletterDetail,
    checkbankcard,
    getcarddetail,
    addbankcard,
    getrebate,
    addbetting,
    getSymbolPrice,
    getMarketChart,
    getKline,
    getHuobiPrice,
    getwithdrawDetermine,
    getRecharge,
    betcenterBetrecord,
    servertime
};