import React, { useRef, useEffect, useState } from 'react';
import { StyleSheet, View, Text, Button, TouchableOpacity, Image, ScrollView, Linking, Platform } from 'react-native';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { commonStyle } from '../components/style';
import { commonConfig } from '../components/config';
import { AntDesign, EvilIcons } from '@expo/vector-icons';
import SpliteLine from '../components/spliteLine';
import AsyncStorage from '@react-native-community/async-storage';
import { getWebsiteInfo, getSymbolPrice, getHuobiPrice, getKline } from '../api/apis';
import { navigateRequireLogin, virtual } from '../components/util';
import NetInfo from "@react-native-community/netinfo";
import TextTicker from 'react-native-text-ticker';
// import Swiper from 'react-native-swiper';
// import Slider from 'react-slick';

import Carousel from '../components/Carousel';

let Swiper
if (Platform.OS !== 'web') {
    Swiper = require('react-native-swiper');
}

const Home = () => {

    const navigation = useNavigation();
    const { i18n, t } = useTranslation();
    const [isLogin, setIsLogin] = useState(false);
    const [websiteInfo, setWebsiteInfo] = useState(false);
    const [curr, setCurr] = useState('USD');
    const [banner, setBanner] = useState([]);
    const [announcement, setAnnouncement] = useState([])
    const [announcementIndex, setAnnouncementIndex] = useState(0);

    const initialData = Object.keys(virtual());

    const initialDataList = initialData.map((currency) => ({
        currency,
        amount: '--',
        percentage: '--',
        low: '--',
        high: '--',
        vol: '--',
        count: '--',
        klineData: []
    }));
    const [dataList, setDataList] = useState(initialDataList);

    useEffect(() => {
        init()
        fetchAllData()

        const unsubscribe = NetInfo.addEventListener((state) => {
            if (state.isConnected) {
                // 有网络连接，继续执行您的逻辑
                // navigation.navigate('NetWork');
            } else {
                // 无网络连接，跳转到另一个页面
                navigation.navigate('NetWork');
            }
        });

        const intervalId = setInterval(fetchAllData, 60000);
        return () => {
            unsubscribe();
            clearInterval(intervalId)
        };
    }, []);

    useFocusEffect(
        React.useCallback(() => {
            isLoggedIn()
        }, [])
    );

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    // const fetchAllData = async () => {
    //     const updatedDataList = await Promise.all(
    //         dataList.map(async (item, index) => {
    //             try {
    //                 // 设置每个请求之间的延迟，例如，每个请求之间等待毫秒
    //                 await delay(index * 500);

    //                 const symbolPrice = await getSymbolPrice(item.currency);
    //                 if (symbolPrice) {
    //                     item.amount = symbolPrice;
    //                 } else {
    //                     const huobiPrice = await getHuobiPrice(item.currency);
    //                     if (huobiPrice) {
    //                         item.amount = huobiPrice;
    //                     }
    //                 }
    //                 const kline = await getKline(item.currency, '1day', 2);
    //                 if (kline) {
    //                     item.low = kline.klineData[0].low;
    //                     item.high = kline.klineData[0].high;
    //                     item.vol = kline.klineData[0].vol;
    //                     item.count = kline.klineData[0].count;
    //                     item.percentage = kline.changePercentage;
    //                 }
    //                 return item;
    //             } catch (error) {
    //                 // console.error(error);
    //                 return item;
    //             }
    //         })
    //     );
    //     setDataList(updatedDataList);
    // };

    const fetchAllData = async () => {
        for (let index = 0; index < dataList.length; index++) {
            try {
                // 设置每个请求之间的延迟，例如，每个请求之间等待毫秒
                await delay(index * 100);

                const item = dataList[index];

                const huobiPrice = await getHuobiPrice(item.currency);

                if (huobiPrice) {
                    item.amount = huobiPrice;
                } else {
                    const symbolPrice = await getSymbolPrice(item.currency);
                    if (symbolPrice) {
                        item.amount = symbolPrice;
                    }
                }

                const kline = await getKline(item.currency, '1day', 2);

                if (kline) {
                    item.low = kline.klineData[0].low;
                    item.high = kline.klineData[0].high;
                    item.vol = kline.klineData[0].vol;
                    item.count = kline.klineData[0].count;
                    item.percentage = kline.changePercentage;
                }

                // 更新单个数据项
                const updatedDataList = [...dataList];
                updatedDataList[index] = item;
                setDataList(updatedDataList);
            } catch (error) {
                // 处理错误，例如记录错误信息或采取其他操作
                // console.error(error);
            }
        }
    };

    const isLoggedIn = async () => {
        if (await AsyncStorage.getItem('userinfo')) {
            setIsLogin(true)
        } else {
            setIsLogin(false)
        }
    }

    const init = async () => {

        const response = await getWebsiteInfo();
        if (response.status == '1') {
            await AsyncStorage.setItem('websiteInfo', JSON.stringify(response));
            setWebsiteInfo(response)
            setBanner(response.Index_Data.swiper_data_)
            setAnnouncement(response.Index_Data.announcement)
            if (response.Data.curr != "") {
                // setCurr(response.Data.curr)
            }
            // const interval = setInterval(() => {
            //     setAnnouncementIndex((prevIndex) => {
            //         const nextIndex = prevIndex + 1;
            //         if (nextIndex >= response.Index_Data.announcement.length) {
            //             return 0; // 回到第一条公告
            //         }
            //         return nextIndex;
            //     });
            // }, 3000); // 每隔3秒切换公告

            // return () => {
            //     clearInterval(interval); // 清除定时器
            // };
        }
    }

    const goToLanguageScreen = () => {
        navigation.navigate('Language');
    }

    const goToLoginScreen = () => {
        navigation.navigate('Login');
    }

    const goToMyScreen = () => {
        navigation.navigate('My');
    }

    const goToExchangeScreen = (klineData, amount, currency, percentage, low, high, vol, count) => {
        const route = {
            name: 'Exchange', // 路由地址
            params: { klineData, amount, currency: currency },
        };
        navigateRequireLogin(navigation, route);
    }

    // const goToMessageNotificationsScreen = () => {
    //     const route = {
    //         name: 'MessageNotifications', // 路由地址
    //         // params: { userId: '123' }, // 路由参数
    //         // screen: 'Home'
    //     };
    //     navigateRequireLogin(navigation, route);
    // }

    const handleBannerClick = (link) => {
        if (link != '') {
            Linking.openURL(link);
        }
    };

    //轮播图
    const swiperRef = useRef(null);

    // const settings = {
    //     autoplay: true,
    //     autoplaySpeed: 3000,
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    // };
    // const images = [
    //     { id: 1, url: 'https://via.placeholder.com/300x200?text=Image+1' },
    //     { id: 2, url: 'https://via.placeholder.com/300x200?text=Image+2' },
    //     { id: 3, url: 'https://via.placeholder.com/300x200?text=Image+3' },
    //     { id: 4, url: 'https://via.placeholder.com/300x200?text=Image+4' },
    //     { id: 5, url: 'https://via.placeholder.com/300x200?text=Image+5' }
    // ];

    return (
        <View style={styles.container}>
            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                style={styles.contentContainer}
            >
                <View >
                    <View style={styles.header}>
                        <View style={styles.loginContainer}>
                            {isLogin && <TouchableOpacity onPress={goToMyScreen}>
                                <Image style={styles.icon} source={require('../../assets/imgs/tabs/my.png')} />
                            </TouchableOpacity>}
                            {!isLogin && <TouchableOpacity onPress={goToLoginScreen}>
                                <Text style={styles.loginText}>{t('login')}</Text>
                            </TouchableOpacity>}
                        </View>
                        <TouchableOpacity onPress={goToLanguageScreen}>
                            <Image style={styles.icon} source={require('../../assets/imgs/home/global.png')} />
                        </TouchableOpacity>

                    </View>

                    {banner.length > 0 && Platform.OS === 'web' &&
                        <View>
                            <Carousel data={banner} />
                        </View>
                    }

                    {banner.length > 0 && Swiper && <View style={styles.swiperContainer}>
                        <Swiper
                            ref={swiperRef}
                            loop={true}
                            autoplay={true}
                            showsPagination={false} //不显示圆点
                            showsButtons={false}  //为false时不显示控制按钮
                            autoplayTimeout={4}
                            style={styles.swiper}
                        >
                            {banner.map((item, index) => (
                                <TouchableOpacity style={styles.slide} key={index} onPress={() => handleBannerClick(item.link)}>
                                    <Image style={styles.image} source={{ uri: commonConfig.img_url + item.image }} />
                                </TouchableOpacity>
                            ))}
                        </Swiper>
                    </View>}
                    <View style={styles.sound}>
                        <AntDesign name="sound" size={18} color="#0ECB81" style={{ marginTop: 12 }} />
                        <View style={styles.soundTextContainer}>

                            {Platform.OS !== 'web' && <View numberOfLines={1} ellipsizeMode="clip" style={{ width: '88%', position: 'relative', overflow: 'hidden' }}>
                                <TextTicker
                                    style={{ color: '#fff' }}
                                    duration={8000}
                                    loop
                                    bounce
                                    repeatSpacer={50}
                                    marqueeOnMount
                                >
                                    {announcement.length > 0 && announcement[0].title}

                                </TextTicker>
                            </View>}

                            {Platform.OS == 'web' && <View style={{ flex: 1, marginLeft: 10, marginTop: 3 }}>
                                <Text numberOfLines={1} ellipsizeMode="clip" style={{ color: '#fff' }}></Text>
                            </View>}


                            {/* <Text style={styles.soundText}>{announcement.length > 0 && announcement[0].title}</Text> */}
                        </View>
                        <TouchableOpacity style={{ marginTop: 12 }} onPress={() => navigateRequireLogin(navigation, { name: 'Message', params: { title: t('systemAnnouncement'), type: 1 } })}>
                            {/* <TouchableOpacity onPress={() => navigateRequireLogin(navigation, { name: 'MessageNotifications' })}> */}
                            <EvilIcons name="navicon" size={20} color="#fff" />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.rowContainerBox}>
                        <View style={styles.rowContainer}>

                            {dataList.slice(0, 3).map((item, index) => (
                                <TouchableOpacity onPress={() => goToExchangeScreen(item.klineData, item.amount, item.currency, item.percentage, item.low, item.high, item.vol, item.count)} key={index}>
                                    <View style={styles.columnContainer} key={index}>
                                        <View style={styles.row}>
                                            <Text style={styles.text}>{item.currency}</Text>
                                            <Text style={[styles.greyText, { lineHeight: 24 }]}>/{curr}</Text>
                                        </View>
                                        <Text style={[item.percentage > 0 ? styles.greenText : styles.redText, { fontSize: 16 }]}>{item.amount}</Text>
                                        <Text style={[item.percentage > 0 ? styles.greenText : styles.redText, { fontSize: 14 }]}>{item.percentage}{item.percentage != '--' && '%'}</Text>
                                    </View>
                                </TouchableOpacity>
                            ))}
                        </View>
                    </View>
                    <View style={styles.listContainer}>
                        <View style={styles.listTitle}>
                            <Text style={styles.listTitleText}>{t('exchange')}</Text>
                            <Text style={styles.listTitleText}>{t('latestPrice')}</Text>
                            <Text style={styles.listTitleText}>24h</Text>
                        </View>
                        <SpliteLine />
                        <View>
                            <View style={{ flex: 1 }}>
                                {dataList.map((item, index) => (
                                    <TouchableOpacity onPress={() => goToExchangeScreen(item.klineData, item.amount, item.currency, item.percentage, item.low, item.high, item.vol, item.count)} key={index}>
                                        <View style={styles.rowItem}>
                                            <View style={styles.flexRow}>
                                                {item.currency == 'BTC' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/BTC.png')} />
                                                }
                                                {item.currency == 'ETH' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/ETH.png')} />
                                                }
                                                {item.currency == 'BCH' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/BCH.png')} />
                                                }
                                                {item.currency == 'UNI' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/UNI.png')} />
                                                }
                                                {item.currency == '1INCH' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/1INCH.png')} />
                                                }
                                                {item.currency == 'LTC' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/LTC.png')} />
                                                }
                                                {item.currency == 'XRP' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/XRP.png')} />
                                                }
                                                {item.currency == 'ETC' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/ETC.png')} />
                                                }
                                                {item.currency == 'DOGE' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/DOGE.png')} />
                                                }
                                                {item.currency == 'FIL' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/FIL.png')} />
                                                }
                                                {item.currency == 'UMA' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/UMA.png')} />
                                                }
                                                {item.currency == 'AAVE' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/AAVE.png')} />
                                                }
                                                {item.currency == 'BAL' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/BAL.png')} />
                                                }
                                                {item.currency == 'CHZ' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/CHZ.png')} />
                                                }
                                                {item.currency == 'HT' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/HT.png')} />
                                                }
                                                {item.currency == 'TRX' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/TRX.png')} />
                                                }
                                                {item.currency == 'EOS' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/EOS.png')} />
                                                }
                                                {item.currency == 'ADA' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/ADA.png')} />
                                                }
                                                {item.currency == 'USDC' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/USDC.png')} />
                                                }
                                                {item.currency == 'DOT' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/Polkadot.png')} />
                                                }
                                                {item.currency == 'XMR' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/XMR.png')} />
                                                }
                                                {item.currency == 'BSV' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/BSV.png')} />
                                                }
                                                {item.currency == 'DASH' &&
                                                    <Image style={styles.currencyImage} source={require('../../assets/imgs/currency/DASH.png')} />
                                                }
                                                <Text style={styles.itemCurrency}>{item.currency}</Text>
                                                <Text style={styles.greyText}>/{curr}</Text>
                                            </View>
                                            <Text style={styles.itemMoney}>{item.amount}</Text>
                                            <View
                                                style={item.percentage >= 0 ? styles.itemGreenContainer : styles.itemRedContainer}
                                            >
                                                <Text style={item.percentage >= 0 ? styles.itemGreenText2 : styles.itemRedText2}>{item.percentage}{item.percentage != '--' && '%'}</Text>
                                            </View>
                                        </View>
                                    </TouchableOpacity>
                                ))}
                            </View>
                        </View>
                    </View>
                </View>
            </ScrollView >
        </View >
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center',
    },
    contentContainer: {
        width: '98%',
        marginTop: 10
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    icon: {
        width: 35,
        height: 35,
    },
    loginContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: 7
    },
    loginText: {
        color: '#fff',
        fontSize: 16,
    },
    swiperContainer: {
        marginTop: 10,
        paddingHorizontal: 10,
    },
    swiper: {
        height: 200,
    },
    slide: {
        flex: 1,
        alignItems: 'center',
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: 10
    },
    sound: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        height: 60
    },
    soundTextContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1
    },
    soundText: {
        color: '#fff',
        marginLeft: 10,
    },
    rowContainerBox: {
        flex: 1,
        backgroundColor: '#1B1D2A',
        borderRadius: 3,
        padding: 10,
    },
    rowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    columnContainer: {
        justifyContent: 'center',
    },
    row: {
        flexDirection: 'row',
    },
    text: {
        color: '#fff',
        fontSize: 16,
    },
    greyText: {
        color: '#4F5665',
    },
    greenText: {
        color: '#0ECB81',
    },
    redText: {
        color: '#F5465C',
    },
    rowItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 10,
        paddingVertical: 5
    },
    flexRow: {
        flexDirection: 'row'
    },
    itemMoney: {
        color: commonStyle.textWhiteColor,
        textAlign: 'left'
    },
    itemCurrency: {
        color: '#fff',
        fontSize: 14
    },
    itemGreenContainer: {
        backgroundColor: '#1e363a',
        width: 65,
        borderRadius: 2,
        height: 30,
        justifyContent: 'center'
    },
    itemGreenText: {
        color: '#fff',
        textAlign: 'center',

    },
    itemRedContainer: {
        backgroundColor: '#3b2937',
        width: 65,
        borderRadius: 2,
        height: 30,
        justifyContent: 'center',
    },
    itemText: {
        color: '#fff',
        textAlign: 'center',
    },
    itemGreenText2: {
        color: '#3db485',
        textAlign: 'center'
    },
    itemRedText2: {
        color: '#f5465c',
        textAlign: 'center'
    },
    listContainer: {
        backgroundColor: '#1B1D2A',
        borderRadius: 3,
        padding: 10,
        marginTop: 10
    },
    listTitle: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 3
    },
    listTitleText: {
        color: '#707A8A'
    },
    currencyImage: {
        borderRadius: 999,
        width: 20,
        height: 20,
        marginRight: 10
    },
});

export default Home;