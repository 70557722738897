import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, TextInput, TouchableOpacity, ScrollView ,Linking} from 'react-native';
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import SpliteLine from '../components/spliteLine';
import { commonStyle } from '../components/style';
import { useNavigation,useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next'
import AsyncStorage from '@react-native-community/async-storage';
import { navigateRequireLogin } from '../components/util';
import { getWebsiteInfo } from '../api/apis';

const Customer = () => {
    const { t, i18n } = useTranslation();
    const navigation = useNavigation();
    const [inputText, setInputText] = useState('');
    const [displayedQuestions, setDisplayedQuestions] = useState([]);
    const [isLogin, setIsLogin] = useState(false);
    const [domain, setDomain] = useState(false);
    
    const languages = {
        en: [
            'How to withdraw',
            'How to deposit',
            'How to sell',
            'How to buy',
            'How to check balance',
            'How to bind bank card',
            'How to cancel order'
        ],
        cn: [
            '如何提现',
            '如何充值',
            '如何卖出',
            '如何买入',
            '如何查询余额',
            '如何绑定银行卡',
            '如何取消订单'
        ],
        es: [
            'Cómo retirar',
            'Cómo depositar',
            'Cómo vender',
            'Cómo comprar',
            'Cómo verificar el saldo',
            'Cómo vincular una tarjeta bancaria',
            'Cómo cancelar un pedido'
        ],
        pt: [
            'Como sacar',
            'Como depositar',
            'Como vender',
            'Como comprar',
            'Como verificar o saldo',
            'Como vincular cartão de banco',
            'Como cancelar pedido'
        ],
        vn: [
            'Làm thế nào để rút tiền',
            'Làm thế nào để nạp tiền',
            'Làm thế nào để bán',
            'Làm thế nào để mua',
            'Làm thế nào để kiểm tra số dư',
            'Làm thế nào để liên kết thẻ ngân hàng',
            'Làm thế nào để hủy đơn hàng'
        ],
        id : [
            'Bagaimana cara menarik uang',
            'Bagaimana cara mengisi uang',
            'Bagaimana cara menjual',
            'Bagaimana cara membeli',
            'Bagaimana cara memeriksa saldo',
            'Bagaimana cara menghubungkan kartu bank',
            'Bagaimana cara membatalkan pesanan'
          ]
    };

    console.log('语言')
    console.log(i18n.language)
    const [questionList, setQuestionList] = useState(languages[i18n.language]);

    useEffect(() => {
        setDisplayedQuestions(questionList.slice(0, 5));
        init()
    }, []);

    useFocusEffect(
        React.useCallback(() => {
            init()
        }, [])
    );

    const init = async () => {
        if (await AsyncStorage.getItem('userinfo')) {
            setIsLogin(true)
        } else {
            setIsLogin(false)
        }

        const websiteInfo = await getWebsiteInfo();
        console.log('websiteInfoStorage')
        // console.log(websiteInfo)
        if (websiteInfo.status == '1') {
            setDomain(websiteInfo.Data.default_domain)
        }
    }

    const handleQuestionChanged = (text) => {
        setInputText(text);
    };

    const handleCustomer = () => {
        // console.log('输入框内容：', inputText);
        if(domain){
            Linking.openURL( domain + 'Server');
        }

        // navigateRequireLogin(navigation, {name: 'Home'})
    };

    const handleQuestionTitle = (text) => {
        console.log('内容', text);
    };

    const handleLogin = () => {
        navigation.navigate('Login');
    };

    const handleReplace = () => {
        randomizeQuestions();
    };

    const randomizeQuestions = () => {
        const randomIndexes = [];
        while (randomIndexes.length < 5) {
            const index = Math.floor(Math.random() * questionList.length);
            if (!randomIndexes.includes(index)) {
                randomIndexes.push(index);
            }
        }
        const newDisplayedQuestions = randomIndexes.map((index) => questionList[index]);
        setDisplayedQuestions(newDisplayedQuestions);
    };

    const inputPlaceholder = t('selectQuestion');

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: commonStyle.bgColor,
            alignItems: 'center',
        },
        input: {
            backgroundColor: '#2C3445',
            color: commonStyle.textGreyColor,
            paddingHorizontal: 10,
            paddingVertical: 5,
            borderRadius: 5,
            width: '75%',
        },
        questionText: {
            color: commonStyle.textGreyColor,
            marginVertical: 5,
        },
        buttonText: {
            color: '#FFF',
        },
        buttonTextSmall: {
            color: commonStyle.textGreyColor,
        },
        buttonContainer: {
            marginTop: 30,
            backgroundColor: '#0ECB81',
            borderRadius: 5,
            flexDirection: 'row',
            width: 100,
            height: 30,
            justifyContent: 'center',
            alignItems: 'center',
        },
        loginContainer: {
            marginTop: 30,
            marginBottom: 10,
            backgroundColor: '#1B1D2A',
            borderRadius: 5,
            height: 120,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        scrollView: {
            width: '90%',
        },
        questionSection: {
            backgroundColor: '#1B1D2A',
            borderRadius: 5,
            marginBottom: 20,
            paddingHorizontal: 15,
            paddingVertical: 10,
            marginTop:5
        },
        buttonCustomer: {
            borderRadius: 5,
            marginBottom: 20,
            backgroundColor: '#0ECB81',
            flexDirection: 'row',
            height: 40,
            justifyContent: 'center',
            alignItems: 'center'
        },
        replay: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        }
    });

    return (
        <View style={styles.container}>
            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                style={styles.scrollView}
            >
                <View style={{ justifyContent: 'center', marginTop: 30 }}>
                    {/* <View style={{ flexDirection: 'row-reverse' }}>
                        <TextInput
                            style={styles.input}
                            placeholder={inputPlaceholder}
                            placeholderTextColor={commonStyle.textGreyColor}
                            onChangeText={handleQuestionChanged}
                        />
                    </View> */}

                    {/* 判断是否登录 */}
                    {!isLogin && <View style={styles.loginContainer}>
                        <Text style={{ color: commonStyle.textGreyColor }}>{t('pleaseLogInToAskAQuestion')}</Text>
                        <View>
                            <TouchableOpacity onPress={handleLogin}>
                                <View style={styles.buttonContainer}>
                                    <Text style={styles.buttonText}>{t('loginNow')}</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                    </View>}

                    <View style={styles.questionSection}>
                        <View>
                            <Text style={{ color: commonStyle.textGreyColor, fontSize: 16, marginVertical: 5 }}>{t('FAQs')}</Text>
                        </View>
                        <SpliteLine />
                        <View>
                            {displayedQuestions.map((question, index) => (
                                <React.Fragment key={index}>
                                    <TouchableOpacity onPress={() => handleQuestionTitle(question)}>
                                        <Text style={styles.questionText}>{question}</Text>
                                    </TouchableOpacity>
                                    <SpliteLine />
                                </React.Fragment>
                            ))}
                        </View>
                        <View style={{ marginTop: 10 }}>
                            <TouchableOpacity onPress={handleReplace} style={styles.replay}>
                                <MaterialIcons name="replay" size={14} color={commonStyle.textGreyColor} />
                                <Text style={styles.buttonTextSmall}> {t('change')}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <TouchableOpacity onPress={handleCustomer}>
                        <View style={styles.buttonCustomer}>
                            <AntDesign name="customerservice" size={10} color="#FFF" />
                            <Text style={styles.buttonText}> {t('contactCustomerService')}</Text>
                        </View>
                    </TouchableOpacity>
                </View>
            </ScrollView>
        </View>
    );
};

export default Customer;