import React, { useRef, useEffect, useState } from 'react';
import { StyleSheet, View, Text, Image, ScrollView, TextInput, TouchableOpacity, Platform, Clipboard, Modal, ActivityIndicator, Dimensions } from 'react-native';
import { commonStyle } from '../components/style';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import SpliteLine from '../components/spliteLine'
import { AntDesign } from '@expo/vector-icons';
import QRCodeSvg from 'react-native-qrcode-svg';
import { useTranslation } from 'react-i18next'
import * as MediaLibrary from 'expo-media-library';
import * as FileSystem from 'expo-file-system';
import { getPromotionTest, getWebsiteInfo } from '../api/apis';
import AsyncStorage from '@react-native-community/async-storage';
import { isLoggedIn, vipGrade } from '../components/util';
import { commonConfig } from '../components/config';
// import HTML from 'react-native-render-html';
import { useWindowDimensions } from 'react-native';
import { WebView } from 'react-native-webview';
import Toast, { DURATION } from 'react-native-easy-toast'
import QRCode from 'qrcode-generator';
import { Svg, Rect } from 'react-native-svg'; // 导入需要的 SVG 组件

// import Clipboard from '@react-native-clipboard/clipboard'


// http://127.0.0.1:8081/manageInvite 隐藏的生成下级代理页
const Publicize = () => {
    const { width } = useWindowDimensions();
    const { t } = useTranslation();
    const navigation = useNavigation();
    const toastRef = useRef(null);
    const [qrCodeValue, setQrCodeValue] = useState('');
    const [dataMsg, setDataMsg] = useState('');
    const [isEnd, setIsEnd] = useState(false);
    const [userInfo, setUserInfo] = useState([]);
    const [vip, setVip] = useState('');  // vip等级
    const [avatar, setAvatar] = useState(''); // 头像
    const [showNo, setShowNo] = useState(false); // 是否可以领取
    const [teamPeople, setTeamPeople] = useState(0); // 团队总数
    const [team1People, setTeam1People] = useState(0); // 直属下级增加
    const [canGetPrize, setCanGetPrize] = useState(0); // 可领佣金
    const [content, setContent] = useState(''); // 教程内容
    // const [unit, setUnit] = useState(''); // 金额单位
    const [tab, setTab] = useState('1');
    const [receive, setReceive] = useState(t('receive'));
    const [proxySalary, setProxySalary] = useState([]); //代理薪资
    const [stateList, setStateList] = useState([t('all'), t('received'), t('receive'), t('expired')]);
    const [domain, setDomain] = useState('');
    const webViewRef = useRef(null);
    const qrCodeRef = useRef(null);

    // web模式二维码
    const [qrCode, setQrCode] = useState('');


    useEffect(() => {
        init()
    }, []);

    useFocusEffect(
        React.useCallback(() => {
            init()
        }, [])
    );

    const init = async () => {
        isLoggedIn(navigation)

        const userinfo = await AsyncStorage.getItem('userinfo');
        if (userinfo) {
            setUserInfo(JSON.parse(userinfo))
        }

        const websiteInfo = await getWebsiteInfo();
        // console.log('websiteInfoStorage')
        // console.log(websiteInfo)
        if (websiteInfo.status == '1') {
            setDomain(websiteInfo.Data.default_domain)
        }

        //代理薪水
        getPromotionTest({ data: `{"commissionlist":{"time":2,"state":0}}` }).then(response => {
            // console.log('代理薪资')

            // response.status = '1'

            if (response.status == '1') {
                var proxydate = response.Data.proxydate;

                // proxydate = [
                //     {reward:1000,People:2,details:1}
                // ]

                // console.log(proxydate);

                var today = new Date();
                var options = { timeZone: 'Asia/Singapore' };
                var singaporeTime = today.toLocaleString('en-US', options);
                var date = new Date(singaporeTime);

                var year = date.getFullYear();
                var month = (date.getMonth() + 1).toString().padStart(2, '0');
                var day = date.getDate().toString().padStart(2, '0');
                var reward = 0;

                var data_list = [];
                for (var key in proxydate) {
                    if (year + '-' + month + '-' + day == key && proxydate[key].details == "1") {
                        proxydate[key].details = +proxydate[key].details + 1
                    }
                    reward = +reward + proxydate[key].reward;
                    data_list.push({
                        time: key,
                        num: proxydate[key].People,
                        sum: +proxydate[key].bet / 100,
                        comm: +proxydate[key].reward / 100,
                        source: t("bonusDistribution"),
                        state: +proxydate[key].details + 1,
                    });
                }
                reward = reward / 100;
                // console.log(data_list)
                setProxySalary(data_list)

            }
        }).catch(error => {

        })

        getPromotionTest().then(response => {
            // console.log(response)
            // response.status = 0
            // response.Data.Code = -11
            // response.Data.msg = '活动已结束'

            if (response.status == '0') {
                if (response.Data.Code == "-11") {
                    // toastRef.current.show(response.Data.msg);
                    setIsEnd(true)
                    setDataMsg(response.Data.msg) // 未开启活动
                }
            }
            if (response.status == '1') {
                let txtContent = response.Data.sysproxy.txtContent
                txtContent = txtContent.replace('[server_address_image]', commonConfig.img_url)
                setContent(txtContent)
                setVip(vipGrade(response.Data1.item[0].user_integral))
                setAvatar(commonConfig.img_url + 'images/common/Avatar/' + response.Data1.item[0].user_system_avatar + '.jpg')
                if (response.Icode && response.Icode.invitation_code != null) {
                    setQrCodeValue("register?id=" + response.Icode.invitation_code)

                    // this.lnk_show = true;
                    // this.lnk_show_1 = false;
                    let res = response.Data.data;
                    if (typeof res[2] !== 'undefined') {
                        setTeam1People(response.Data.data[0][0])
                        for (let i = 0; i < res[2].length; i++) {
                            canGetPrize += Number(res[2][i]);
                        }
                    }
                    if (canGetPrize > 0) {
                        setCanGetPrize(Math.round(canGetPrize) / 100)
                        setShowNo(true)
                        if (response.Data.Whether_to_receive == "2") {
                            setShowNo(false)
                            setReceive(t('notClaimable'))
                            console.log('不可领取')
                        }
                        if (response.Data.Whether_to_receive == "3") {
                            setShowNo(false)
                            setReceive(t('received'))
                            console.log('已领取')
                        }
                    }

                    if (response.Data.team.People >= 0) {
                        setTeamPeople(response.Data.team.People)
                    }
                }
            }
        }).catch(error => {
            // console.error(error);
        });
    }

    // 领取奖励
    const receiveAward = () => {
        let Can_get_prize = canGetPrize + "";
        Can_get_prize = Can_get_prize.replace(/,/g, ""); //去掉奖励中的逗号
        getPromotionTest({ data: `{"Available_bonus":"'${Can_get_prize}'"}` })
            .then(response => {
                // console.log(response)
                if (response.status == '1') {
                    let res = response.Data.data; //获取到数据
                    if (res.Whether_to_receive == "1") {
                        toastRef.current.show(response.data.msg);
                    }
                }
                if (response.status == '0') {
                    toastRef.current.show(response.Data.msg);
                }
            }).catch(error => {
                // console.error(error);
            });
    }

    const selectTab = async (value) => {
        setTab(value)
    }

    // 复制
    const handleCopy = () => {
        if (domain != '' && qrCodeValue != '') {
            Clipboard.setString(domain + qrCodeValue);
        }
    }

    let myQRCode = useRef();
    const saveQRCodeToBase64 = () => {

        if (Platform.OS != 'web') {
            myQRCode.toDataURL((data) => {
                // console.log(data); // 输出二维码的base64字符串
                // 你可以在此方法内部将base64编码的数据保存为图片
                saveQRToDiskAsync(data)
            });

        }

        if (Platform.OS == 'web') {

            console.log('web 二维码')


            const typeNumber = 4; // QR Code 类型
            const errorCorrectionLevel = 'L'; // 错误修正级别

            // 使用 qrcode-generator 库生成 QR Code 数据
            const qrcode = QRCode(typeNumber, errorCorrectionLevel);

            console.log('web二维码')
            console.log(domain + qrCodeValue)

            qrcode.addData(domain + qrCodeValue);
            qrcode.make();

            // 获取生成的 QR Code 数据并设置为 State
            const href = (qrcode.createDataURL(4, 0));
            const link = document.createElement('a');
            link.href = href;
            link.download = 'qrcode.png'; // 设置下载文件名
            link.click();
        }

    };


    async function saveQRToDiskAsync(qrCodeBase64) {
        const { status } = await MediaLibrary.requestPermissionsAsync(true);
        if (status !== 'granted') {
            console.log('需要存储权限才能保存二维码');
            return;
        }

        try {
            const fileUri = FileSystem.cacheDirectory + 'your_image.jpg';

            // 写入 Base64 数据到文件
            await FileSystem.writeAsStringAsync(fileUri, qrCodeBase64, {
                encoding: FileSystem.EncodingType.Base64,
            });

            // 保存文件到相册
            const asset = await MediaLibrary.createAssetAsync(fileUri);
            await MediaLibrary.createAlbumAsync('exchance', asset, false);
            console.log('图片已保存到相册');
        } catch (error) {
            console.error('保存图片时出错：', error);
        }
    }

    const customHTML = `
    <!DOCTYPE html>
    <html>
    <head>
        <meta content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" name="viewport">
        <style>
        body {background-color: #121420;}
        p {
            color: #fff  !important
        }
        span {
            color: #fff !important
        }
        </style>
    </head>
    <body>
        <div id="content">
            ${content}
        </div>
    </body>
    </html>
    `;


    return (
        <View style={styles.container}>

            {isEnd ?
                <Text style={{ color: '#fff' }}>{dataMsg}</Text>
                :

                <View >
                    <ScrollView
                        showsVerticalScrollIndicator={false}
                        showsHorizontalScrollIndicator={false}
                        style={styles.scrollView}
                    >
                        <View style={[styles.rowContainer, { paddingHorizontal: 10, paddingVertical: 10 }]}>
                            <View style={styles.imageContainer}>
                                {avatar && <Image style={[styles.image, { width: 70, height: 70, borderRadius: 90 }]} source={{ uri: avatar }} />}
                            </View>
                            <View style={styles.contentContainer}>
                                <Text style={styles.whiteText}>{userInfo.user_name ? userInfo.user_name : userInfo.user_account_number}</Text>
                                <Text style={[styles.whiteText, styles.marginTop15]}>{vip.inte}</Text>
                            </View>
                            <View style={styles.actionContainer}>
                                {showNo ?
                                    <TouchableOpacity onPress={() => receiveAward()}>
                                        <Text style={styles.actionText}>{receive}</Text>
                                    </TouchableOpacity>
                                    :
                                    <Text style={[styles.actionText, { backgroundColor: 'gray' }]}>{t('receive')}</Text>
                                }
                                <TouchableOpacity onPress={() => selectTab('3')}>
                                    <Text style={[styles.actionText, { backgroundColor: '#465DF5' }]}>{t('receiveHistory')}</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={styles.commissionContainer}>
                                <Text style={styles.commissionText}>{t('commission')}</Text>
                                <Text style={[styles.commissionText, styles.marginTop15]}>{canGetPrize.toFixed(2)}</Text>
                            </View>
                        </View>
                        <View style={[styles.rowContainer, { paddingHorizontal: 10, paddingVertical: 10 }]}>
                            <TouchableOpacity onPress={() => selectTab('1')}>
                                <View style={styles.iconTextContainer}>
                                    <Image style={styles.image} source={require('../../assets/imgs/publicize/promotionTutorial.png')} />
                                    <Text style={styles.whiteText}>{t('myPromotion')}</Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => selectTab('2')}>
                                <View style={styles.iconTextContainer}>
                                    <Image style={styles.image} source={require('../../assets/imgs/publicize/myPromotion.png')} />
                                    <Text style={styles.whiteText}>{t('promotionTutorial')}</Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => selectTab('3')}>
                                <View style={styles.iconTextContainer}>
                                    <Image style={styles.image} source={require('../../assets/imgs/publicize/agentSalary.png')} />
                                    <Text style={styles.whiteText}>{t('proxySalary')}</Text>
                                </View>
                            </TouchableOpacity>
                        </View>

                        {tab == 1 && <View>
                            {qrCodeValue && domain != '' && <View style={styles.columnContainer}>
                                <Text style={[styles.whiteText, { marginBottom: 5 }]}>{t('shareUrl')}</Text>
                                <SpliteLine />
                                <View style={styles.rowContainer}>
                                    <View style={styles.linkContainer}>
                                        <Text style={styles.linkText}>{t('myLink')}</Text>
                                    </View>
                                    <View style={styles.inputContainer}>
                                        <TextInput
                                            style={styles.textInput}
                                            value={domain + qrCodeValue}
                                            editable={false}
                                        />
                                        <TouchableOpacity style={styles.copyIconContainer} onPress={handleCopy}>
                                            <AntDesign name="copy1" size={15} color="#828B9F" />
                                        </TouchableOpacity>
                                    </View>
                                </View>
                                <View style={styles.rowContainer}>
                                    <View style={styles.qrCodeContainer}>
                                        <View style={{ padding: 5 }}>

                                            <QRCodeSvg
                                                value={domain + qrCodeValue}
                                                size={100}
                                                color="black"
                                                backgroundColor="white"
                                                getRef={(c) => (myQRCode = c)}
                                            />

                                            <Svg width={0} height={0}>
                                                {/* 在 SVG 中绘制二维码 */}
                                                {qrCode && <Rect width={200} height={200} fill={`url(${qrCode})`} />}
                                            </Svg>

                                        </View>
                                        <TouchableOpacity onPress={saveQRCodeToBase64}>
                                            <Text style={styles.clickSaveText}>{t('clickSave')}</Text>
                                        </TouchableOpacity>
                                    </View>
                                    <View style={styles.actionsContainer}>
                                        <TouchableOpacity onPress={handleCopy}>
                                            <Text style={styles.copyText}>{t('copyLink')}</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={saveQRCodeToBase64}>
                                            <Text style={styles.saveText}>{t('saveQrcode')}</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            </View>}

                            <View style={styles.columnContainer}>
                                <Text style={[styles.whiteText, { marginBottom: 5 }]}>{t('commission')}</Text>
                                <SpliteLine />
                                <View style={styles.rowContainer}>
                                    <Text style={styles.grayText}>{t('eligibleCommission')}</Text>
                                    <Text style={styles.grayText}>{canGetPrize.toFixed(2)}</Text>
                                </View>
                            </View>
                            <View style={styles.columnContainer}>
                                <Text style={[styles.whiteText, { marginBottom: 5 }]}>{t('performance')}</Text>
                                <SpliteLine />
                                <View style={styles.rowContainer}>
                                    <Text style={styles.grayText}>{t('totalNumberTeams')}</Text>
                                    <Text style={styles.grayText}>{teamPeople}{t('person')}</Text>
                                </View>
                                <SpliteLine />
                                <View style={styles.rowContainer}>
                                    <Text style={styles.grayText}>{t('increaseDirectSubordinates')}</Text>
                                    <Text style={styles.grayText}>{team1People}{t('person')}</Text>
                                </View>
                            </View>
                        </View>}


                    </ScrollView>
                </View>
            }

            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={300}
                textStyle={{ color: '#fff' }}
            />
            {tab == 2 &&
                <>
                    {Platform.OS == 'web' && <iframe srcDoc={customHTML} width="100%" height="100%" style={{ border: '0px' }} />}
                    {Platform.OS != 'web' && <WebView
                        style={{ flex: 1, backgroundColor: '#000' }}
                        source={{ html: customHTML }}
                        javaScriptEnabled={true} />}
                </>
            }
            {tab == 3 &&
                <View>
                    {proxySalary.length == 0 &&
                        <View style={{ justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                            <Text style={{ color: '#fff' }}>{t('noData')}</Text>
                        </View>
                    }
                    {proxySalary.length > 0 &&
                        <View style={styles.columnContainer}>
                            {proxySalary.map((item, index) => (
                                <View key={index}>
                                    <View >
                                        <Text style={styles.grayText}>{item.time}</Text>
                                        <Text style={styles.grayText}>{`${item.num}人/${item.sum}`}</Text>
                                    </View>
                                    <View>
                                        <Text style={styles.grayText}>{item.source}</Text>
                                        <Text style={styles.grayText}>{t('commission')}${item.comm}</Text>
                                    </View>
                                    <View>
                                        <Text style={styles.grayText}>
                                            {stateList[item.state]}
                                        </Text>
                                    </View>
                                </View>
                            ))}
                        </View>
                    }

                </View>
            }

        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,

        // justifyContent: 'center',
        flexDirection: 'colum'
    },
    scrollView: {
        // width: '95%',
        marginHorizontal: 10,
        marginTop: 10
    },
    rowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#222430',
        borderRadius: 5,
        // paddingVertical: 10,
        marginTop: 10,
        marginBottom: 10,
    },
    imageContainer: {
        justifyContent: 'center',
    },
    contentContainer: {
        justifyContent: 'center',
    },
    actionContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    commissionContainer: {
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    iconTextContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    columnContainer: {
        flexDirection: 'column',
        backgroundColor: '#222430',
        borderRadius: 5,
        marginBottom: 10,
        padding: 10,
    },
    linkContainer: {
        flex: 1,
        justifyContent: 'center',
    },
    inputContainer: {
        flex: 3,
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: 'gray',
        borderRadius: 3,
        alignItems: 'center',
    },
    qrCodeContainer: {
        backgroundColor: '#FFF',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: 5,
    },
    actionsContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 2,
    },
    image: {
        width: 30,
        height: 30,
    },
    whiteText: {
        color: '#fff',
    },
    marginTop15: {
        marginTop: 15,
    },
    actionText: {
        color: '#fff',
        backgroundColor: '#13B777',
        width: 70,
        padding: 5,
        borderRadius: 5,
        textAlign: 'center',
        marginTop: 5,
    },
    commissionText: {
        color: '#F5465C',
    },
    grayText: {
        color: '#828B9F',
    },
    linkText: {
        color: '#828B9F',
        padding: 2,
    },
    textInput: {
        flex: 1,
        color: '#828B9F',
        paddingLeft: 5,
    },
    copyIconContainer: {
        marginLeft: 2,
    },
    saveText: {
        color: '#fff',
        backgroundColor: '#465DF5',
        height: 30,
        lineHeight: 30,
        textAlign: 'center',
        borderRadius: 5,
        width: 150,
    },
    clickSaveText: {
        color: '#fff',
        backgroundColor: '#13B777',
        height: 25,
        lineHeight: 25,
        textAlign: 'center',
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
    },
    copyText: {
        color: '#fff',
        backgroundColor: '#13B777',
        width: 150,
        padding: 6,
        borderRadius: 5,
        textAlign: 'center',
        margin: 10,
    },
    content: {
        // backgroundColor:'#fff',
        // fontSize: 16,
    },
});

export default Publicize;