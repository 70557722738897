import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, View, Text, TextInput, TouchableOpacity, Modal, ScrollView } from 'react-native';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import SpliteLine from '../components/spliteLine'
import { commonStyle } from '../components/style'
import Toast from 'react-native-easy-toast'
import AsyncStorage from '@react-native-community/async-storage';
import { getWebsiteInfo, addbankcard, getcarddetail } from '../api/apis';
import { Entypo, AntDesign } from '@expo/vector-icons';

const AddBankCard = (props) => {

    const { t } = useTranslation();

    // 在组件中调用 useNavigation() 钩子获取导航对象
    const navigation = useNavigation();

    console.log(props.route.params)

    // const [curr, setCurr] = useState('USD');
    const toastRef = useRef(null);
    const [isEdit, setIsEdit] = useState(props.route.params.isEdit);
    const [selectBank, setSelectBank] = useState([]);
    const [password, setPassword] = useState('');
    const [inputBankName, setInputBankName] = useState('USDT(trc20)');
    const [modalVisible, setModalVisible] = useState(false);
    const [name, setName] = useState('');
    const [bankId, setBankId] = useState(false);
    const [cardNumber, setCardNumber] = useState('');
    const [confirmCardNumber, setConfirmCardNumber] = useState('');
    const [nameDisabledid, setNameDisabledid] = useState(false);

    useEffect(() => {
        init()
    }, []);

    useFocusEffect(
        React.useCallback(() => {
            init()
        }, [])
    );

    React.useLayoutEffect(() => {
        navigation.setOptions({
            title: props.route.params.title,
            headerStyle: {
                backgroundColor: commonStyle.bgColor,
                borderBottomWidth: 0,
                height:30
            },
            headerTitleStyle: {
            },
        });
    }, [navigation]);

    const openModal = (type) => {
        setModalVisible(true);
    };
    const closeModal = () => {
        setModalVisible(false);
    };

    const handleSelectBank = (value) => {
        setInputBankName(value)
        setModalVisible(false);
    }

    const init = async () => {
        const response = await getWebsiteInfo();
        if (response.status == '1') {
            const curr = response.Data.curr
            // if (response.Data.curr != "") {
            //     setCurr(response.Data.curr)
            // }
            // console.log('curr')
            // console.log(response.Data.curr)

            let data0 = [
                "USDT(trc20)"
            ],
                data2 = [
                    "工商银行",
                    "建设银行",
                    "农业银行",
                    "民生银行",
                    "招商银行",
                    "交通银行",
                    "中国银行",
                    "邮政储蓄",
                    "中信银行",
                    "兴业银行",
                    "华夏银行",
                    "浦发银行",
                    "广发银行",
                    "平安银行",
                    "光大银行",
                    "其他",
                ],
                data1 = [
                    "ธนาคารกรุงเทพ", //盤谷銀行
                    "ธนาคารกสิกรไทย", //開泰銀行
                    "ธนาคารกรุงไทย", //泰京銀行
                    "ธนาคารทหารไทย", //軍人銀行 (泰國)
                    "ธนาคารไทยพาณิชย์", //泰國匯商銀行
                    "ธนาคารสแตนดาร์ดชาร์เตอร์ด", //渣打銀行
                    "ธนาคารยูโอบี", //大華銀行
                    "ธนาคารกรุงศรีอยุธยา", //大城銀行
                    "ธนาคารออมสิน", //政府儲蓄銀行
                    "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย", //泰國進出口銀行
                    "ธนาคารธนชาต", //泰納昌銀行
                    "ธนาคารทิสโก้", //鐵士古銀行
                    "ธนาคารเกียรตินาคิน", //甲那金銀行
                    "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร", //农业银行和农业合作社
                    "ธนาคารซีไอเอ็มบีไทย", // 泰国联昌银行
                    "ธนาคารทหารไทยธนชาต", //TTB
                    "ธนาคาร แลนด์ แอนด์ เฮ้าส์", //LHBANK
                    "ธนาคารอาคารสงเคราะห์", //GHB
                    "ธนาคารไอซีบีซี (ไทย)", //ICBC 中国工商银行
                    "ธนาคารไทยเคดิต เพื่อรายย่อย", //TCRB
                    "ธนาคารมิซูโฮ คอร์ปอเรต", // MHCB
                    "ธนาคารอิสลามแห่งประเทศไทย", //ISBT
                    "ธนาคารดอยซ์แบงก์ เอจี", //DBBK
                    "ธนาคารแห่งประเทศจีน(ไทย)จำกัด(มหาชน)", //中國銀行（泰國）股份有限公司
                    "ธนาคารชูมิโตโม มิตซุย แบงกิ้ง คอร์ปอเรชั่น", //(三井住友銀行)
                    "ธนาคารซิตี้แบงก์", //(花旗(泰國)銀行)  "", // 花旗银行
                    "อื่นๆ", // 其他
                ],
                data3 = [
                    "Vietcombank",
                    "ACB",
                    "DongABank",
                    "Techcombank",
                    "Vietinbank",
                    "Sacombank",
                    "BIDV",
                    "Agribank",
                    "Eximbank",
                    "SCB",
                    "VietABank",
                    "ABBank",
                    "Saigonbank",
                    "PG Bank",
                    "Oceanbank",
                    "NamABank",
                    "BacABank",
                    "VIB",
                    "VPBank",
                    "GPBank",
                    "MariTimeBank",
                    "HDBank",
                    "OCB",
                    "TienPhongBank",
                    "Vietbank",
                    "Seabank",
                    "MBBank",
                    "NASBank",
                    "Quoc Dan(NCB)",
                    "SHB",
                    "KienlongBank ",
                    "shinhanbank",
                    "WOORI BANK",
                    "MSB",
                    "LienVietPostBank",
                    "IVB BANK",
                    "CBBANK",
                    "VietCapitalBank",
                    "BaoVietBank",
                    "VDB",
                    "HSBC",
                    "UBank",
                    "CIMB",
                ],
                data4 = [
                    "capitec",
                    "Absa",
                    "FNB",
                    "Nedbank",
                    "tymeBank",
                    "Standard Bank",
                    "standardbank",
                    "absa",
                    "fnb",
                    "nedbank",
                ],
                data5 = [
                    "Access Bank Nigeria",
                    "Zenith Bank Nigeria",
                    "First Bank of Nigeria",
                    "United Bank for Africa",
                    "Guaranty Trust Bank",
                    "Stanbic IBTC Bank",
                    "Eco Bank Nigeria",
                    "Fidelity Bank",
                    "Union Bank of Nigeria",
                    "First City Monument Bank",
                    "Providus Bank",
                    "sterling bank plc",
                ],
                data6 = [
                    "ifsc Bank",
                ],
                data7 = [
                    "EMAIL",
                    "PHONE",
                    "CPF",
                    "CNPJ",
                    "RANDOM",
                ],
                datas = [];

            if (curr == "ZAR") {
                datas = data4;
            } else if (curr == "CNY") {
                datas = data2;
            } else if (curr == "VND") {
                datas = data3;
            } else if (curr == "THB") {
                datas = data1;
            } else if (curr == "NGN") {
                datas = data5;
            } else if (curr == "INR") {
                datas = data6;
            } else if (curr == "BRL") {
                datas = data7;
            }

            for (let i = 0; i < data0.length; i++) {
                if (data0[i] != "") {
                    datas.unshift(data0[i]);
                }
            }

            setSelectBank(datas)
            // console.log(datas)

            if (isEdit) {
                setBankId(props.route.params.id)
                const response = await getcarddetail(props.route.params.id);
                // console.log(response)
                if (response.status == '1') {
                    // console.log('银行卡详情成功')
                    // console.log(response.Data1.item)
                    setName(response.Data1.item[0].user_name)
                    setInputBankName(response.Data1.item[0].user_bank_card_bank_name)

                    if (response.Data1.item[0].user_bank_card_number != "") {
                        setCardNumber(response.Data1.item[0].user_bank_card_number)
                        if (curr == "INR") {
                            let Bankin = this.BankNum.split(';');
                            setCardNumber(Bankin[0])
                        }
                        if (curr == "BRL") {
                            let Bankin = this.BankNum.split(';');
                            setCardNumber(Bankin[0])
                        }
                    }

                    if (response.Data1.Realnameverification == "1") {
                        //有实名验证，禁用
                        setNameDisabledid(true)
                    }

                } else {
                    console.log('银行卡详情失败')
                }
            }
        }

    }

    const handleNameChanged = (text) => {
        setName(text);
    };

    const handleCardNumberChanged = (text) => {
        const filteredText = text.replace(/[^0-9]/g, '');
        setCardNumber(filteredText);
    };

    const handleConfirmCardNumberChanged = (text) => {
        setConfirmCardNumber(text);
    };

    const handlePasswordChanged = (text) => {
        setPassword(text)
    };

    const handleConfirm = () => {

        if (name === '') {
            toastRef.current.show(t('pleaseEnterName'));
            return;
        }
        if (cardNumber === '') {
            toastRef.current.show(t('pleaseEnterCardNumber'));
            return;
        }
        if (confirmCardNumber != cardNumber) {
            toastRef.current.show(t('confirmCardNumberInconsistency'));
            return;
        }
        if (inputBankName === '') {
            toastRef.current.show(t('pleaseEnterBankName'));
            return;
        }
        if (password === '') {
            toastRef.current.show(t('pleaseEnterSecurityPassword'));
            return;
        }

        const bankCardID = isEdit ? `"BankCardID":"${bankId}"` : "";
        const edit = isEdit ? "1" : "2"
        const data_post = `{"Account_opening_branch":"","Address_":"","BankNum":"${cardNumber}","InputBankName":"${inputBankName}","RealName":"${name}","SafePassword":"${password}","edit":"${edit}",${bankCardID}}`;

        console.log(data_post)

        addbankcard(data_post).then(response => {
            if (response.status == 1) {
                toastRef.current.show(t('success'));
                navigation.goBack()
            } else {
                console.log(response)
                toastRef.current.show(response.Data.msg);
            }
        }).catch(error => {
            // console.error(error);
        });

    };


    return (
        <View style={styles.container}>
            <View style={styles.form}>
                <View>
                    <Text style={styles.label}>{t('name')}</Text>
                    <TextInput
                        style={styles.input}
                        value={name}
                        disabled={nameDisabledid}
                        placeholder={t('pleaseEnterName')}
                        onChangeText={handleNameChanged}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                    />
                    <SpliteLine />
                </View>


                <View>
                    <Text style={styles.label}>{t('cardNumber')}</Text>
                    <TextInput
                        style={styles.input}
                        placeholder={t('pleaseEnterCardNumber')}
                        value={cardNumber}
                        onChangeText={handleCardNumberChanged}
                        keyboardType="numeric"
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                    />
                    <SpliteLine />
                </View>

                <View>
                    <Text style={styles.label}>{t('confirmCardNumber')}</Text>
                    <TextInput
                        style={styles.input}
                        placeholder={t('pleaseEnterCardNumber')}
                        value={confirmCardNumber}
                        onChangeText={handleConfirmCardNumberChanged}
                        keyboardType="numeric"
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                    />
                    <SpliteLine />
                </View>

                <View>
                    <Text style={styles.label}>{t('paymentMethod')}</Text>
                    <TouchableOpacity onPress={openModal}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text style={styles.input}>{inputBankName}</Text>
                            <View style={styles.iconText}>
                                <View><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                    </TouchableOpacity>
                    <SpliteLine />
                </View>

                <View>
                    <Text style={styles.label}>{t('securityPassword')}</Text>
                    <TextInput
                        style={styles.input}
                        secureTextEntry={true}
                        placeholder={t('pleaseEnterSecurityPassword')}
                        onChangeText={handlePasswordChanged}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                    />
                    <SpliteLine />
                </View>

                <TouchableOpacity style={styles.button} onPress={handleConfirm}>
                    <Text style={styles.buttonText}>{t('confirm')}</Text>
                </TouchableOpacity>

            </View>


            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <AntDesign name="down" onPress={() => { closeModal() }}
                                size={24} color="#707070" style={styles.modelDown}
                            />
                        </View>

                        <ScrollView
                            showsVerticalScrollIndicator={false}
                            showsHorizontalScrollIndicator={false}
                        >
                            <View>
                                {selectBank.map((item, index) => (
                                    <TouchableOpacity key={index} onPress={() => handleSelectBank(item)}>
                                        <Text style={[styles.modalText]}>{item}</Text>
                                    </TouchableOpacity>
                                ))}
                            </View>
                        </ScrollView>
                    </View>
                </View>
            </Modal>

            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={200}
                textStyle={{ color: '#fff' }}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,  // 主轴上尺寸比例
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center', // 交叉轴对齐方式
        justifyContent: 'center',//主轴对齐方式
        // flexDirection:"column" //主轴方向
    },
    form: {
        width: '80%',
    },
    label: {
        color: '#fff',
        fontSize: 16,
        marginTop: 10,
    },
    input: {
        color: '#707070',
        marginBottom: 10,
        marginTop: 15,
        height: 30,
        borderWidth: 0,
        borderBottomColor: 'transparent'
    },
    inviteCodeInput: {
        flex: 2,
        width: 230,
    },
    inputContainer: {
        flexDirection: 'row',
    },
    requiredText: {
        flex: 1,
        color: '#fff',
        lineHeight: 45,
        textAlign: 'right',
        fontSize: 18,
    },
    button: {
        backgroundColor: '#2C3445',
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        marginTop: 40,
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
    },
    bottonNavText: {
        color: '#707070',
        fontSize: 16
    },
    iconText: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: -10
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        // backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalContent: {
        backgroundColor: '#1B1D29',
        // padding: 20,
        borderRadius: 10,
        width: '100%',
        height: '70%'
    },
    closeButton: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#fff',
        marginTop: 16,
        textAlign: 'center',
    },
    modelDown: {
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 'auto',
        marginBottom: 15,
        marginTop: 20
    },
    modalText: {
        color: '#8C99B3',
        textAlign: 'center',
        height: 40,
        fontSize: 16,
        lineHeight: 35
    },
    modalTextSelected: {
        backgroundColor: '#0F1017',
        opacity: 0.45
    }
});

export default AddBankCard;
