const environment = 'production';
let apiUrl = [];

if (environment === 'production') {
    apiUrl = [
        'https://zoushi.thai2565.com',
        'https://test.thai2565.com',
    ];
}

if (environment === 'development') {
    apiUrl = [
        'https://test.thai2565.com',
        'https://zoushi.thai2565.com',
    ];
}

if (environment === 'local') {
    apiUrl = [
        'http://local.api.com/proxy.php',
        'https://test.thai2565.com',
        'https://zoushi.thai2565.com',
    ];
}

export default {
    apiUrl, // API地址
    env:environment,
    facebookPixelID:'12345678',
    googleAnalyticsID:'UA-12345678-9',
    tiktokPixelID:'12345678'
};
