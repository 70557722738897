import React, { useState, useEffect } from 'react';
import { View, Image, FlatList, Dimensions, StyleSheet, Linking, TouchableOpacity } from 'react-native';
import { commonConfig } from '../components/config';

const { width } = Dimensions.get('window');

const Carousel = ({ data }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentIndex === data.length - 1) {
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex + 1);
            }
        }, 3000);

        return () => {
            clearInterval(interval);
        };
    }, [currentIndex]);

    const handleBannerClick = (link) => {
        if (link != '') {
            Linking.openURL(link);
        }
    };

    return (
        <View style={styles.container}>

            <FlatList
                data={data}
                horizontal
                pagingEnabled
                showsHorizontalScrollIndicator={false}
                keyExtractor={(item, index) => index.toString()}
                renderItem={({ item, index }) => (
                    <TouchableOpacity style={styles.slide} key={index} onPress={() => handleBannerClick(item.link)}>
                        <Image
                            source={{ uri: commonConfig.img_url + item.image }}
                            style={[styles.image, { opacity: index === currentIndex ? 1 : 0.5 }]}
                        />
                    </TouchableOpacity>
                )}
                onMomentumScrollEnd={(event) => {
                    const contentOffset = event.nativeEvent.contentOffset.x;
                    const index = Math.round(contentOffset / width);
                    setCurrentIndex(index);
                }}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width,
        height: 200,
    },
    image: {
        width,
        height: 200,
    },
});

export default Carousel;
