import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, View, Text, TextInput, TouchableOpacity, Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import SpliteLine from '../components/spliteLine'
import { login, getImageCode, generateToken, userCenter } from '../api/apis';
import { commonStyle } from '../components/style'
import { showAlert } from '../components/showAlert'
import Toast, { DURATION } from 'react-native-easy-toast'
import AsyncStorage from '@react-native-community/async-storage';
import TopNavigationBar from '../components/loginTopNavigationBar';

const LoginScreen = () => {

    const { t } = useTranslation();

    const toastRef = useRef(null);

    // 在组件中调用 useNavigation() 钩子获取导航对象
    const navigation = useNavigation();
    const previousRoute = navigation.getState().routes[navigation.getState().index - 1].name; // MainNav

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [imageCode, setImageCode] = useState('');
    const [imageCodeKey, setImageCodeKey] = useState('');
    const [imageData, setImageData] = useState('');

    const handleBeforeRemove = (e) => {
        const actionType = e.data.action.type;
        // 取消滑动后退行为
        if (actionType === 'GO_BACK' && previousRoute == 'MainNav') {
            e.preventDefault();
        }
    };

    useEffect(() => {
        const unsubscribe = navigation.addListener('beforeRemove', handleBeforeRemove);
        handleGetimagecode()
        return unsubscribe;
    }, [navigation]);

    //获取验证码
    const handleGetimagecode = () => {
        const token = generateToken();
        getImageCode(token, 'login').then(response => {
            if (response.Data.Code === '1') {
                const data = response.Data.data;
                const imageData = `data:image/jpg/png/gif;base64,${data}`;
                const imageCodeKey = token;
                setImageData(imageData);
                setImageCodeKey(imageCodeKey);
                // console.log(`验证码 key 值为：${imageCodeKey}`);
            } else {
                // console.log('不显示验证码');
            }
        }).catch(error => {
            // console.error(error);
        });
    };

    const handleUsernameChanged = text => {
        setUserName(text);
    };

    const handlePasswordChanged = text => {
        setPassword(text);
    };

    const handleImageCode = text => {
        setImageCode(text);
    };

    // 点击登录按钮
    const handleLogin = async () => {
        if (userName === '') {
            toastRef.current.show(t('pleaseEnterUsername'));
            return;
        }
        if (password === '') {
            toastRef.current.show(t('pleaseEnterPassword'));
            return;
        }
        if (imageData !== '' && imageCode === '') {
            toastRef.current.show(t('pleaseEnterImageCode'));
            return;
        }

        await AsyncStorage.removeItem('userinfo');
        await AsyncStorage.removeItem('cookies');
        await AsyncStorage.removeItem('websiteInfoStorage');

        try {
            const response = await login(userName, password, imageCode, imageCodeKey);
            if (response.status == '1') {
                toastRef.current.show(t('success'));

                //获取用户信息
                const ucenter = await userCenter();
                if (ucenter.status == '1') {
                    // console.log('用户信息')
                    // console.log(ucenter.Data1.item[0])
                    await AsyncStorage.setItem('userinfo', JSON.stringify(ucenter.Data1.item[0]));
                    // const userinfo = await AsyncStorage.getItem('userinfo');
                    // console.log(JSON.parse(userinfo));
                    navigation.navigate('MainNav', { screen: 'Home' });
                } else {
                    toastRef.current.show(ucenter.Data1.msg);
                    return
                }

            } else {
                toastRef.current.show(response.Data.msg);
                handleGetimagecode();
                return;
            }

        } catch (error) {
            console.log(`登录请求发生错误：${error}`);
            return;
        }
    };

    const handlePressRegister = () => {
        // Navigate to the register screen
        navigation.navigate('Register');
    };

    const handlePressForgotPassword = () => {
        // navigation.navigate('ForgetPwd');
        navigation.navigate('Customer');
    };

    return (
        <><TopNavigationBar title={t('login')} previousRoute={previousRoute} />
            <View style={styles.container}>
                <View style={styles.form}>
                    <View>
                        <Text style={styles.label}>{t('username')}</Text>
                        <TextInput
                            style={styles.input}
                            placeholder={t('pleaseEnterUsername')}
                            onChangeText={handleUsernameChanged}
                            underlineColorAndroid="transparent"
                            placeholderTextColor="#707070" />
                        <SpliteLine />
                    </View>
                    <View>
                        <Text style={styles.label}>{t('password')}</Text>
                        <TextInput
                            style={styles.input}
                            secureTextEntry={true}
                            placeholder={t('pleaseEnterPassword')}
                            onChangeText={handlePasswordChanged}
                            underlineColorAndroid="transparent"
                            placeholderTextColor="#707070" />
                        <SpliteLine />
                    </View>

                    {imageData && <View>
                        <Text style={styles.label}>{t('imageCode')}</Text>
                        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <TextInput
                                style={styles.input}
                                value={imageCode}
                                placeholder={t('pleaseEnterImageCode')}
                                onChangeText={handleImageCode}
                                underlineColorAndroid="transparent"
                                maxLength={4}
                                placeholderTextColor="#707070" />
                            <Image source={{ uri: imageData }} style={{ width: 100, height: 30, borderWidth: 1 }} />
                        </View>
                        <SpliteLine />
                    </View>}

                    <TouchableOpacity style={styles.button} onPress={handleLogin}>
                        <Text style={styles.buttonText}>{t('login')}</Text>
                    </TouchableOpacity>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
                        <TouchableOpacity onPress={handlePressRegister}>
                            <View>
                                <Text style={styles.bottonNavText}>{t('register')}</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={handlePressForgotPassword}>
                            <View>
                                <Text style={[styles.bottonNavText, { textAlign: 'right' }]}>{t('forgotPassword')}</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>
                <Toast
                    ref={toastRef}
                    style={{ backgroundColor: 'gray' }}
                    position='top'
                    positionValue={200}
                    // fadeInDuration={750}
                    // fadeOutDuration={1000}
                    // opacity={0.8}
                    textStyle={{ color: '#fff' }} />
            </View></>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,  // 主轴上尺寸比例
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center', // 交叉轴对齐方式
        justifyContent: 'center',//主轴对齐方式
        // flexDirection:"column" //主轴方向
    },
    form: {
        width: '80%',
    },
    label: {
        color: '#fff',
        fontSize: 16,
        marginTop: 10,
    },
    input: {
        // placeholderTextColor:'#707070',
        color: '#707070',
        marginBottom: 15,
        marginTop: 15,
        height: 30,
        borderWidth: 0,
        borderBottomColor: 'transparent'
    },
    inviteCodeInput: {
        flex: 2,
        width: 230,
    },
    inputContainer: {
        flexDirection: 'row',
    },
    requiredText: {
        flex: 1,
        color: '#fff',
        lineHeight: 45,
        textAlign: 'right',
        fontSize: 18,
    },
    button: {
        backgroundColor: '#2C3445',
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        marginTop: 40,
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
    },
    bottonNavText: {
        color: '#707070',
        fontSize: 16
    }
});

export default LoginScreen;
