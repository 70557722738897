import React, { useState, useEffect } from 'react';
import { View, Text, Image, TouchableOpacity, FlatList, ActivityIndicator, StyleSheet, RefreshControl } from 'react-native';
import ActiveTabItem from '../components/activeTabItem'
import { commonStyle } from '../components/style'
import { useTranslation } from 'react-i18next';
import { FontAwesome } from '@expo/vector-icons';
import { getTraderecord } from '../api/apis';

const App = () => {

    const { t } = useTranslation();

    const [data, setData] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [page, setPage] = useState(1);
    const [emptyShow, setEmptyShow] = useState(false);

    useEffect(() => {
        // 初始化数据
        handleRefresh()
    }, []);

    // 刷新
    const handleRefresh = () => {
        setRefreshing(true)
        setPage(1)
        setTimeout(() => {
            record()
        }, 1000);
    };

    // 加载更多数据
    const handleLoadMore = () => {
        setLoadingMore(true);
        setPage(parseInt(page) + 1)
        setTimeout(() => {
            record()
        }, 1000);
    };


    //获取记录
    const record = async () => {
        getTraderecord(page, 'cz').then(response => {
            setRefreshing(false);
            setLoadingMore(false);
            if (response.status == 1) {
                console.log(response.Data1.item)
                const updateData = response.Data1.item.map(data => {
                    let statusText = '';
                    switch (data.status) {
                        case '1':
                            statusText = t('processingPayment');
                            break;
                        case '2':
                            statusText = t('deposited');
                            break;
                        case '3':
                            statusText = t('cancelled');
                            break;
                        default:
                            statusText = '';
                    }
                    const addDate = new Date(parseInt(data.add_date)).toLocaleString();
                    const inMoney = (data.inMoney / 100).toFixed(2)
                    return { ...data, status: statusText, add_date: addDate, inMoney: inMoney };
                });
                const dataList = data.concat(updateData)
                if (page > 1) {
                    setData(dataList)
                } else {
                    setData(updateData)
                    if (dataList.length == 0) {
                        setEmptyShow(true)
                    }
                }
            }
        }).catch(error => {
            setRefreshing(false);
            setLoadingMore(false);
            console.log(error);
        });
    };

    const renderItem = ({ item }) => (
        <View style={styles.itemContainer}>
            <View style={styles.itemRowContainer}>
                <View><Text style={styles.itemTextGreen}>{item.status}</Text></View>
            </View>
            <View style={styles.itemRowContainer}>
                <View><Text style={styles.itemTextGray}>{item.add_date}{item.start_time}</Text></View>
                <View>
                    <TouchableOpacity onPress={() => handleIconPress(item)}>
                        <Text style={styles.itemTextGreen}>
                            {item.inMoney}{item.amount}
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );

    return (
        <View style={styles.container}>
            {emptyShow && <View style={{justifyContent: 'center', alignItems: 'center',height: '80%'}}>
                <Text style={{ color: '#fff' }}>{t('noData')}</Text>
            </View>}
            {/* <Image style={styles.image} source={require('../../assets/imgs/common/empty.png')} /> */}

            <FlatList
                data={data}
                renderItem={renderItem}
                keyExtractor={(item, index) => index}
                refreshControl={<RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />}
                onEndReached={!loadingMore && handleLoadMore}
                onEndReachedThreshold={0.1}
                ListFooterComponent={loadingMore && <ActivityIndicator color={commonStyle.green} />}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor
    },
    tabContainer: {
        flexDirection: 'row',
        paddingVertical: 10,
        backgroundColor: '#1B1D29',
    },
    tabItem: {
        flex: 1,
        alignItems: 'center',
        paddingVertical: 10,
    },
    tabText: {
        color: commonStyle.textGreenColor,
    },
    itemTextGreen: {
        color: commonStyle.textGreenColor,
    },
    itemTextGray: {
        color: commonStyle.textGrayColor
    },
    itemContainer: {
        flexDirection: "column",
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderBottomWidth: 1,
        backgroundColor: '#1B1D29',
        marginTop: 5,
    },
    itemRowContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 4
    },
    activeTabItem: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    image: {

    }

});

export default App;