import React, { useState, useRef, useEffect } from 'react';
import { StyleSheet, View, Text, TextInput, TouchableOpacity, Modal, ScrollView } from 'react-native';
import { useTranslation } from 'react-i18next';
import SpliteLine from '../components/spliteLine'
import { commonStyle } from '../components/style'
import Toast, { DURATION } from 'react-native-easy-toast'
import { getWithdraw, getwithdrawDetermine } from '../api/apis';
import { useNavigation } from '@react-navigation/native';
import { AntDesign, Entypo } from '@expo/vector-icons';
import AsyncStorage from '@react-native-community/async-storage';
import { unitconversion } from '../components/util'

const App = () => {
    const { t } = useTranslation();
    const toastRef = useRef(null);

    const [password, setPassword] = useState('');
    const [withdrawalAmount, setWithdrawalAmount] = useState('0.00');
    const [isValidAmount, setValidAmount] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [bankcard, setBankcard] = useState([]);
    const [bankcardId, setBankcardId] = useState('');
    const [bankcardNumber, setBankcardNumber] = useState('');
    const [cashWithdrawalAmount, setCashWithdrawalAmount] = useState('0.00'); //可提现金额
    const [codeAmount, setCodeAmount] = useState('0'); //剩余投注额
    const [curr, setCurr] = useState('USD');
    const [userCashWithdrawals, setUserCashWithdrawals] = useState('0');

    const navigation = useNavigation();

    useEffect(() => {
        init()
    }, []);

    const init = async () => {
        let websiteInfo = await AsyncStorage.getItem('websiteInfo');
        websiteInfo = JSON.parse(websiteInfo);
        const response = await getWithdraw();
        if (response.status == '1') {
            setUserCashWithdrawals(response.Data.user_cash_withdrawals) //今日可提现次数
            if (response.Data.Remaining_percent_Amount == 0) {
                if (websiteInfo.Data.curr != '') {
                    setCurr(websiteInfo.Data.curr)
                }
                setCashWithdrawalAmount(unitconversion(response.Data.user_balance, curr))
            }
            setCodeAmount(unitconversion(response.Data.Remaining_percent_Amount))
            setBankcard(response.Data.Bankcard)
            // console.log(response.Data)
            // if (bankcard.length == 0) {
            //     navigation.navigate('PaymentMethod');
            // }
        }
    }

    const openModal = () => {
        setModalVisible(true);
    };
    const closeModal = () => {
        setModalVisible(false);
    };

    const handleSelectCard = (item) => {
        closeModal()
        setBankcardId(item.id)
        setBankcardNumber(item.user_bank_card_number)
    }

    const handleWithdrawalAmountChanged = text => {
        setWithdrawalAmount(text);
    };

    const handlePasswordChanged = text => {
        setPassword(text);
    };

    const handleConfirm = async () => {
        if (password === '') {
            toastRef.current.show(t('pleaseEnterPassword'));
            return;
        }

        if (withdrawalAmount <= 0) {
            toastRef.current.show(t('invalidAmount'));
            return;
        }

        const regex = /^\d+(\.\d{1,2})?$/;
        if (!regex.test(withdrawalAmount)) {
            toastRef.current.show(t('invalidAmount'));
            return;
        }

        if (bankcardNumber == '') {
            toastRef.current.show(t('withdrawalAccountSelect'));
            return;
        }

        // if (withdrawalAmount > cashWithdrawalAmount) {
        //     toastRef.current.show(t('insufficientBalance'));
        //     return;
        // }

        let tj_data =
            '{"Withdrawal_Amount":"' +
            withdrawalAmount +
            '","Bank_card":"' +
            bankcardId +
            '","safe":"' +
            password +
            '"}';

        try {
            const response = await getwithdrawDetermine(tj_data);
            console.log(response)
            toastRef.current.show(response.Data.msg);
            if (response.status == '1') {
                setWithdrawalAmount('0.00');
                setPassword('');
                return
            }
        } catch (error) {
            console.log(`请求发生错误`);
            console.log(error)
            toastRef.current.show(t('fail'));
            return;
        }
    };

    return (
        <View style={styles.container}>
            <View style={styles.form}>
                <View>
                    <Text style={styles.label}>{t('accountBalance')}</Text>
                    <TextInput
                        style={styles.input}
                        editable={false}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                        value={cashWithdrawalAmount}
                    />
                    <SpliteLine />
                </View>
                <View>
                    <Text style={styles.label}>{t('withdrawableAmount')}</Text>
                    <TextInput
                        style={styles.input}
                        editable={false}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                        value={cashWithdrawalAmount}
                    />
                    <SpliteLine />
                </View>
                <View>
                    <Text style={styles.label}>{t('remainingBettingAmount')}</Text>
                    <TextInput
                        style={styles.input}
                        editable={false}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                        value={codeAmount}
                    />
                    <SpliteLine />
                </View>
                <View>
                    <Text style={styles.label}>{t('withdrawalAccount')}</Text>
                    <View style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
                        <TextInput
                            style={styles.input}
                            editable={false}
                            placeholder={t('withdrawalAccountSelect')}
                            underlineColorAndroid="transparent"
                            placeholderTextColor="#707070"
                            value={bankcardNumber}
                        />
                        <TouchableOpacity onPress={() => { openModal(); }}>
                            <View style={styles.iconText}>
                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </TouchableOpacity>
                    </View>
                    <SpliteLine />
                </View>
                <View>
                    <Text style={styles.label}>{t('withdrawalAmount')}</Text>
                    <TextInput
                        style={[
                            styles.input,
                        ]}
                        placeholder={t('pleaseEnterWithdrawalAmount')}
                        onChangeText={handleWithdrawalAmountChanged}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                        value={withdrawalAmount}
                    />
                    <SpliteLine />
                </View>
                <View>
                    <Text style={styles.label}>{t('securityPassword')}</Text>
                    <TextInput
                        style={styles.input}
                        secureTextEntry={true}
                        placeholder={t('pleaseEnterSecurityPassword')}
                        onChangeText={handlePasswordChanged}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                        value={password}
                    />
                    <SpliteLine />
                </View>
                <TouchableOpacity style={styles.button} onPress={handleConfirm}>
                    <Text style={styles.buttonText}>{t('confirm')}</Text>
                </TouchableOpacity>
                {/* <Text style={{ color: '#707070' }}>{t('numberOfWithdrawalsAvailableToday')}: {userCashWithdrawals}</Text>
                <Text style={{ color: '#707070' }}>{t('lowestSingleWithdrawal')}: 10</Text> */}
            </View>

            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <AntDesign name="down" onPress={() => { closeModal() }}
                                size={24} color="#707070" style={styles.modelDown}
                            />
                        </View>
                        <ScrollView
                            showsVerticalScrollIndicator={false}
                            showsHorizontalScrollIndicator={false}
                        >
                            <View>
                                {bankcard.map((item, index) => (
                                    <TouchableOpacity key={index} onPress={() => handleSelectCard(item)}>
                                        <View style={styles.card} >
                                            <Text style={[styles.modalText]}>{item.user_bank_card_bank_name}</Text>
                                            <Text style={[styles.modalText]}>{item.user_bank_card_number}</Text>
                                        </View>
                                    </TouchableOpacity>
                                ))}
                            </View>
                        </ScrollView>
                    </View>
                </View>
            </Modal>


            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={200}
                // fadeInDuration={750}
                // fadeOutDuration={1000}
                // opacity={0.8}
                textStyle={{ color: '#fff' }}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,  // 主轴上尺寸比例
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center', // 交叉轴对齐方式
        justifyContent: 'center',//主轴对齐方式
        // flexDirection:"column" //主轴方向
    },
    form: {
        width: '80%',
    },
    label: {
        color: '#fff',
        fontSize: 16,
        marginTop: 10,
    },
    input: {
        color: '#707070',
        marginBottom: 5,
        marginTop: 5,
        height: 30,
        borderWidth: 0,
        borderBottomColor: 'transparent',
    },
    inviteCodeInput: {
        flex: 2,
        width: 230,
    },
    inputContainer: {
        flexDirection: 'row',
    },
    requiredText: {
        flex: 1,
        color: '#fff',
        lineHeight: 45,
        textAlign: 'right',
        fontSize: 18,
    },
    button: {
        backgroundColor: '#2C3445',
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        // backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalContent: {
        backgroundColor: '#1B1D29',
        // padding: 20,
        borderRadius: 10,
        width: '100%',
        height: '80%'
    },
    modelDown: {
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 'auto',
        marginBottom: 15,
        marginTop: 20
    },
    modalText: {
        color: '#8C99B3',
        textAlign: 'center',
        height: 40,
        fontSize: 16,
        lineHeight: 35
    },
    modalTextSelected: {
        backgroundColor: '#0F1017',
        opacity: 0.45
    },
    iconText: {
        flexDirection: 'row',
        alignItems: 'center',
        // marginBottom: 10,
        color: '#828B9F',
    },
    goArrow: {
        fontSize: 20,
        // fontWeight: 'bold',
    },
    card: {
        backgroundColor: '#FAAE27',
        borderRadius: 5,
        justifyContent: 'center',
        // paddingVertical: 15,
        // paddingHorizontal: 10,
        marginTop: 10,
    },
});

export default App;