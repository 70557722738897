import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, Image, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { commonStyle } from '../components/style';
import { navigateRequireLogin } from '../components/util';
import { AntDesign } from '@expo/vector-icons';
import { checkbankcard } from '../api/apis';

const PaymentMethod = () => {
    const navigation = useNavigation();
    const { t } = useTranslation();
    const [bankcard, setBankcard] = useState([]);

    useEffect(() => {
        init()
    }, []);

    const init = () => {
        checkbankcard().then(response => {
            if (response.status == 1) {
                setBankcard(response.Data1.item)
                console.log(response.Data1.item)
            }
        }).catch(error => {
            // console.error(error);
        });
    }

    return (
        <View style={styles.container}>
            <View style={styles.cardContainer}>

                {bankcard.map((item, index) => (
                    <TouchableOpacity key={index} style={styles.card}
                        onPress={() => navigateRequireLogin(navigation, { name: 'AddBankCard', params: { id: item.id, isEdit: true, title: t('updateBankCard') } })}
                    >
                        <View style={styles.cardContent}>
                            <View style={styles.cardTitleIcon}>
                                <Image style={styles.cardIcon} source={require('../../assets/imgs/pay/bank.png')} />
                                <Text style={styles.cardTitle}>{item.user_bank_card_bank_name}</Text>
                            </View>
                            <Text style={styles.cardText}></Text>
                            <Text style={styles.cardText}>{item.user_bank_card_number}</Text>
                        </View>
                    </TouchableOpacity>
                ))}

                {bankcard.length <= 5 &&
                    <TouchableOpacity style={styles.addButton}
                        onPress={() => navigateRequireLogin(navigation, { name: 'AddBankCard', params: { isEdit: false, title: t('addBankCard') } })}
                    >
                        <Text style={styles.addButtonIcon}>+</Text>
                        <Text style={styles.addButtonLabel}>{t('addNewPaymentAccount')}</Text>
                    </TouchableOpacity>
                }
            </View>

            <Text style={{ color: '#888', marginTop: 10 }}>{t('linkedPaymentMethod')} {bankcard.length} {t('linked5PaymentMethod')} </Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: commonStyle.bgColor,
    },
    cardContainer: {
        width: '95%',
    },
    card: {
        backgroundColor: '#FAAE27',
        borderRadius: 5,
        justifyContent: 'center',
        paddingVertical: 15,
        paddingHorizontal: 10,
        marginTop: 10,
    },
    cardContent: {
        flexDirection: 'colum',
        justifyContent: 'flex-start',
        // alignItems: 'center',
    },
    cardTitleIcon: {
        flexDirection: 'row'
    },
    cardIcon: {
        height: 30,
        width: 30,
    },
    cardTitle: {
        color: '#fff',
        fontSize: 18,
        marginTop: 4,
        marginLeft: 5,
        marginBottom: 4,
    },
    cardText: {
        color: '#fff',
        marginTop: 4,
    },
    addButton: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B1D29',
        borderRadius: 5,
        paddingVertical: 40,
        marginTop: 10,
    },
    addButtonIcon: {
        fontSize: 40,
        color: '#fff',
    },
    addButtonLabel: {
        color: '#fff',
        fontSize: 17,
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        // backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalContent: {
        backgroundColor: commonStyle.bgColor,
        // backgroundColor: '#1B1D29',
        // padding: 20,
        borderRadius: 10,
        width: '100%',
        height: '100%'
    },
    modelDown: {
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 'auto',
        marginBottom: 15,
        marginTop: 20
    },
    modalText: {
        color: '#8C99B3',
        textAlign: 'center',
        height: 40,
        fontSize: 16,
        lineHeight: 35
    },
    form: {
        width: '90%',
        justifyContent: 'center',
        margin: 'auto'
    },
    input: {
        color: '#1B1D29',
        backgroundColor: '#1B1D29',
        marginBottom: 15,
        marginTop: 15,
        height: 30,
        borderWidth: 0,
        borderBottomColor: 'transparent'
    },
    label: {
        color: '#fff',
        fontSize: 16
    }
});

export default PaymentMethod;