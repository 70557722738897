import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, View, Text, TextInput, TouchableOpacity, Image } from 'react-native';
import { useTranslation } from 'react-i18next';
import SpliteLine from '../components/spliteLine'
import { commonStyle } from '../components/style'
import { showAlert } from '../components/showAlert'
import { register, getImageCode, generateToken } from '../api/apis';
import Toast, { DURATION } from 'react-native-easy-toast'
import { useNavigation } from '@react-navigation/native';

const RegisterScreen = () => {
    const { t } = useTranslation();
    const toastRef = useRef(null);
    const [userName, setUserName] = useState('');
    const [inviteCode, setInviteCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [imageCode, setImageCode] = useState('');
    const [imageCodeKey, setImageCodeKey] = useState('');
    const [imageData, setImageData] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    const navigation = useNavigation();

    useEffect(() => {
        handleGetimagecode()
    }, []);

    //获取验证码
    const handleGetimagecode = () => {
        const token = generateToken();
        getImageCode(token,'reg').then(response => {
            if (response.Data.Code === '1') {
                const data = response.Data.data;
                const imageData = `data:image/jpg/png/gif;base64,${data}`;
                const imageCodeKey = token;
                setImageData(imageData);
                setImageCodeKey(imageCodeKey);
                // console.log(`验证码 key 值为：${imageCodeKey}`);
            } else {
                // console.log('不显示验证码');
            }
        }).catch(error => {
            // console.error(error);
        });
    };

    const handleUsernameChanged = text => {
        setUserName(text);
    };

    const handleInviteCodeChanged = text => {
        setInviteCode(text);
    };

    const handlePasswordChanged = text => {
        setPassword(text);
    };

    const handleConfirmPasswordChanged = text => {
        setConfirmPassword(text);
    };

    const handleImageCode = text => {
        setImageCode(text);
    };

    const handleRegister = async () => {
        if(isSubmitting){
            return
        }
        if (userName === '') {
            toastRef.current.show(t('pleaseEnterUsername'));
            return;
        }
        if (inviteCode === '') {
            toastRef.current.show(t('pleaseEnterInviteCode'));
            return;
        }
        if (password === '') {
            toastRef.current.show(t('pleaseEnterPassword'));
            return;
        }

        var regex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,16}$/;
        if (!regex.test(password)) {
            toastRef.current.show(t('pleaseEnter6-16charactersOfLettersAndNumbers'));
            return;
        }

        if (confirmPassword === '') {
            toastRef.current.show(t('pleaseEnterconfirmPassword'));
            return;
        }
        if (password !== confirmPassword) {
            toastRef.current.show(t('confirmPasswordInconsistency'));
            return;
        }

        if (imageCode == '') {
            toastRef.current.show(t('pleaseEnterImageCode'));
            return;
        }

        try {
            setIsSubmitting(true)
            const response = await register(userName, password, inviteCode, imageCode,imageCodeKey);
            console.log(response);
            setIsSubmitting(false)
            handleGetimagecode()
            if (response.status == '1') {
                toastRef.current.show(t('success'));
                navigation.navigate('Login');
                return;
            }
            toastRef.current.show(response.Data.msg);
        } catch (error) {
            // console.error(`请求发生错误：${error}`);
            setIsSubmitting(false)
            handleGetimagecode()
            return
        }

    };

    return (
        <View style={styles.container}>
            <View style={styles.form}>
                <View>
                    <Text style={styles.label}>{t('username')}</Text>
                    <TextInput
                        style={styles.input}
                        placeholder={t('pleaseEnterUsername')}
                        onChangeText={handleUsernameChanged}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                    />
                    <SpliteLine />
                </View>
                <View>
                    <Text style={styles.label}>{t('inviteCode')}</Text>
                    <View style={styles.inputContainer}>
                        <TextInput
                            style={[styles.input, styles.inviteCodeInput]}
                            placeholder={t('pleaseEnterInviteCode')}
                            onChangeText={handleInviteCodeChanged}
                            underlineColorAndroid="transparent"
                            placeholderTextColor="#707070"
                        />
                        <Text style={styles.requiredText}>{t('required')}</Text>
                    </View>
                    <SpliteLine />
                </View>
                <View>
                    <Text style={styles.label}>{t('setPassword')}</Text>
                    <TextInput
                        style={styles.input}
                        secureTextEntry={true}
                        placeholder={t('pleaseEnter6-16charactersOfLettersAndNumbers')}
                        onChangeText={handlePasswordChanged}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                    />
                    <SpliteLine />
                </View>
                <View>
                    <Text style={styles.label}>{t('confirmPassword')}</Text>
                    <TextInput
                        style={styles.input}
                        secureTextEntry={true}
                        placeholder={t('pleaseEnterconfirmPassword')}
                        onChangeText={handleConfirmPasswordChanged}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                    />
                    <SpliteLine />
                </View>

                <View>
                    <Text style={styles.label}>{t('imageCode')}</Text>
                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <TextInput
                            style={styles.input}
                            value={imageCode}
                            placeholder={t('pleaseEnterImageCode')}
                            onChangeText={handleImageCode}
                            underlineColorAndroid="transparent"
                            maxLength={4}
                            placeholderTextColor="#707070"
                        />
                        {imageData &&  <Image source={{ uri: imageData }} style={{ width: 100, height: 30, borderWidth: 1 }} />}
                       
                    </View>
                    <SpliteLine />
                </View>

                <TouchableOpacity style={styles.button} onPress={handleRegister}>
                    <Text style={styles.buttonText}>{t('registerLogin')}</Text>
                </TouchableOpacity>
            </View>
            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={200}
                // fadeInDuration={750}
                // fadeOutDuration={1000}
                // opacity={0.8}
                textStyle={{ color: '#fff' }}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,  // 主轴上尺寸比例
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center', // 交叉轴对齐方式
        justifyContent: 'center',//主轴对齐方式
        // flexDirection:"column" //主轴方向
    },
    form: {
        width: '80%',
    },
    label: {
        color: '#fff',
        fontSize: 16,
        marginTop: 10,
    },
    input: {
        color: '#707070',
        marginBottom: 15,
        marginTop: 15,
        height: 30,
        borderWidth: 0,
        borderBottomColor: 'transparent',
    },
    inviteCodeInput: {
        flex: 2,
        width: 230,
    },
    inputContainer: {
        flexDirection: 'row',
    },
    requiredText: {
        flex: 1,
        color: '#fff',
        lineHeight: 45,
        textAlign: 'right',
        fontSize: 18,
    },
    button: {
        backgroundColor: '#2C3445',
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        marginTop: 40,
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
    },
});

export default RegisterScreen;