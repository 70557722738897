import { Platform } from 'react-native';
import 'intl-pluralrules'
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './languages/en.json'
import cn from './languages/cn.json'
import es from './languages/es.json'
import pt from './languages/pt.json'
import vn from './languages/vn.json'
import id from './languages/id.json'
import AsyncStorage from '@react-native-community/async-storage';
import * as Localization from 'expo-localization';

// import AsyncStorageUtil from '../components/asyncStorageUtil .js'


// let RNLocalize
// if (Platform.OS == 'web') {
//     RNLocalize = require('react-native-localize');
// }

// 存储数据
const storeData = async (key, value) => {
    try {
        await AsyncStorage.setItem(key, value);
        // console.log('数据已存储');
    } catch (error) {
        // console.log('存储数据时出错:', error);
    }
};

// 获取数据
const getData = async (key) => {
    try {

        const currentLanguage = Localization.locale;
        console.log('当前语言:', currentLanguage);
  
        let value = await AsyncStorage.getItem(key);
        if (!value) {
            // const locales = RNLocalize.getLocales();
            // value = locales[0].languageCode
            // if (value == 'zh') {
            //     value = 'cn'
            // }
            // if (value == 'vi') {
            //     value = 'vn'
            // }
            value = currentLanguage
            if(currentLanguage == 'zh-CN' || currentLanguage == 'zh-TW'){
                value = 'cn'
            }
            if(currentLanguage == 'en-US' || currentLanguage == 'en-GB'){
                value = 'en'
            }
            if(currentLanguage == 'es' || currentLanguage == 'es-MX'){
                value = 'es'
            }
            if(currentLanguage == 'pt-BR' || currentLanguage == 'pt-PT'){
                value = 'es'
            }
            if(currentLanguage == 'vi'){
                value = 'es'
            }
            storeData('language', value)
        }

        if (value !== null) {
            console.log('获取的数据:', value);

            const defaultLanguage = 'en'; // 默认语言
            const initialLanguage = value || defaultLanguage;

            // 初始化 i18n
            i18n.use(initReactI18next).init({
                resources,
                lng: initialLanguage, // 设置语言为初始语言
                fallbackLng: defaultLanguage, // 如果找不到指定语言的翻译，使用默认语言
                interpolation: {
                    escapeValue: false,
                },
            });

        } else {
            console.log('未找到数据');

            console.log('系统语言')


        }
    } catch (error) {
        console.log('获取数据时出错:', error);
    }
};

const resources = {
    en: {
        translation: en
    },
    cn: {
        translation: cn
    },
    es: {
        translation: es
    },
    pt: {
        translation: pt
    },
    vn: {
        translation: vn
    },
    id: {
        translation: id
    }
}

// 从本地存储获取语言，如果没有则使用默认语言
// const savedLanguage = localStorage.getItem('language');
// const savedLanguage = await AsyncStorageUtil.retrieveData('language');

const savedLanguage = 'en'

const defaultLanguage = 'en'; // 默认语言
const initialLanguage = savedLanguage || defaultLanguage;

// 初始化 i18n
i18n.use(initReactI18next).init({
    resources,
    lng: initialLanguage, // 设置语言为初始语言
    fallbackLng: defaultLanguage, // 如果找不到指定语言的翻译，使用默认语言
    interpolation: {
        escapeValue: false,
    },
});

getData('language')

// 在语言切换时保存用户选择的语言到本地存储
i18n.on('languageChanged', (lng) => {
    // localStorage.setItem('language', lng);
    // AsyncStorageUtil.storeData('language', lng);
    storeData('language', lng)
});

export default i18n;
