import React, { useState, useRef, useEffect } from 'react';
import { StyleSheet, View, Text, Button, TouchableOpacity, ScrollView, TextInput, Modal } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next'
import { commonStyle } from '../components/style';
import { getRechargeTron, getRecharge } from '../api/apis';
import SpliteLine from '../components/spliteLine'
import Toast from 'react-native-easy-toast'

const Deposit = () => {

    const navigation = useNavigation();

    const { i18n, t } = useTranslation();
    const toastRef = useRef(null);

    const [rechargeWayBank, setRechargeWayBank] = useState(false);
    const [amount, setAmount] = useState('0.00');
    const [name, setName] = useState('');
    const [rechargeWayBankId, setRechargeWayBankId] = useState('');

    useEffect(() => {
        init()
    }, []);

    const init = () => {
        getRechargeTron().then(response => {
            console.log(response)
            if (response.status == 1) {
                const rechargeWayBank = response.item.RechargeWayBank[0]
                setRechargeWayBankId(rechargeWayBank.id)
                // response.item.RechargeWayBank.length = 0
                if (rechargeWayBank.length == 0) {
                    navigation.navigate('PaymentMethod');
                    return
                }
                setRechargeWayBank(rechargeWayBank)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const handleAmountChanged = text => {
        setAmount(text);
    };

    const handleNameChanged = text => {
        setName(text);
    };


    const handleConfirm = async () => {
        if (name === '') {
            toastRef.current.show(t('pleaseEnterName'));
            return;
        }

        if (amount < 10) {
            console.log(amount)
            toastRef.current.show(t('pleaseEnter10-1000'));
            return;
        }

        const params = `{"Money":"${amount}","NickName":"${name}","id":"${rechargeWayBankId}"}`
        getRecharge(params).then(response => {
            console.log('充值')
            console.log(response)
            if (response.status == 1) {
                toastRef.current.show(response.msg);
                setAmount('0.00');
                setName('')
            } else {
                toastRef.current.show(response.Data.msg);
                return;
            }
        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <View style={styles.container}>

            {rechargeWayBank && <View style={styles.form}>
                <View>
                    <Text style={{ color: '#fff', fontSize: 20, textAlign: 'center' }}>{rechargeWayBank.bank}</Text>
                </View>
                <View>
                    <Text style={styles.label}>{t('payee')}</Text>
                    <TextInput
                        style={styles.input}
                        editable={false}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                        value={rechargeWayBank.payee}
                    />
                    <SpliteLine />
                </View>
                <View>
                    <Text style={styles.label}>{t('accountNumber')}</Text>
                    <TextInput
                        style={styles.input}
                        editable={false}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                        value={rechargeWayBank.collectionaccount}
                    />
                    <SpliteLine />
                </View>
                <View>
                    <Text style={styles.label}>{t('bankBranch')}</Text>
                    <TextInput
                        style={styles.input}
                        editable={false}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                        value={rechargeWayBank.branchname}
                    />
                    <SpliteLine />
                </View>
                <View>
                    <Text style={styles.label}>{t('recharge')}{t('amount')}</Text>
                    <TextInput
                        style={styles.input}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                        onChangeText={handleAmountChanged}
                        value={amount}
                    />
                    <SpliteLine />
                </View>
                <View>
                    <Text style={styles.label}>{t('name')}</Text>
                    <TextInput
                        style={styles.input}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                        onChangeText={handleNameChanged}
                        placeholder={t('pleaseEnterName')}
                        value={name}
                    />
                    <SpliteLine />
                </View>
                <TouchableOpacity style={styles.button} onPress={handleConfirm}>
                    <Text style={styles.buttonText}>{t('confirm')}</Text>
                </TouchableOpacity>

            </View>}

            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={200}
                // fadeInDuration={750}
                // fadeOutDuration={1000}
                // opacity={0.8}
                textStyle={{ color: '#fff' }}
            />
        </View>

    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,  // 主轴上尺寸比例
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center', // 交叉轴对齐方式
        justifyContent: 'center',//主轴对齐方式
        // flexDirection:"column" //主轴方向
    },
    form: {
        width: '80%',
    },
    label: {
        color: '#fff',
        fontSize: 16,
        marginTop: 10,
    },
    input: {
        color: '#707070',
        marginBottom: 5,
        marginTop: 5,
        height: 30,
        borderWidth: 0,
        borderBottomColor: 'transparent',
    },
    inviteCodeInput: {
        flex: 2,
        width: 230,
    },
    inputContainer: {
        flexDirection: 'row',
    },
    requiredText: {
        flex: 1,
        color: '#fff',
        lineHeight: 45,
        textAlign: 'right',
        fontSize: 18,
    },
    button: {
        backgroundColor: '#2C3445',
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        // backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalContent: {
        backgroundColor: '#1B1D29',
        // padding: 20,
        borderRadius: 10,
        width: '100%',
        height: '80%'
    },
    modelDown: {
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 'auto',
        marginBottom: 15,
        marginTop: 20
    },
    modalText: {
        color: '#8C99B3',
        textAlign: 'center',
        height: 40,
        fontSize: 16,
        lineHeight: 35
    },
    modalTextSelected: {
        backgroundColor: '#0F1017',
        opacity: 0.45
    },
    iconText: {
        flexDirection: 'row',
        alignItems: 'center',
        // marginBottom: 10,
        color: '#828B9F',
    },
    goArrow: {
        fontSize: 20,
        // fontWeight: 'bold',
    },
    card: {
        backgroundColor: '#FAAE27',
        borderRadius: 5,
        justifyContent: 'center',
        // paddingVertical: 15,
        // paddingHorizontal: 10,
        marginTop: 10,
    },
});

export default Deposit;