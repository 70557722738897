import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { useTranslation } from 'react-i18next';
import { commonStyle } from '../components/style'
import { getAnnouncementdetail, getletterDetail } from '../api/apis';

const MessageDetail = (props) => {
    const { t } = useTranslation();

    const [msgId, setMsgId] = useState(props.route.params.id);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [pushDate, setPushDate] = useState('');

    useEffect(() => {
        if (props.route.params.type == 1) {
            getAnnouncement()
        } else {
            getletter()
        }
    }, []);

    const getAnnouncement = () => {
        getAnnouncementdetail(msgId).then(response => {
            console.log(response)
            if (response.status == '1') {
                const data = response.Data.announcement[0]
                console.log(data)
                setTitle(data.title)
                setContent(data.txtContent)
                setPushDate(new Date(parseInt(data.time_year) * 1000).toLocaleString())
            }
        }).catch(error => {
            // console.error(error);
        });
    }

    const getletter = () => {
        getletterDetail(msgId).then(response => {
            console.log(response)
            if (response.status == '1') {
                const data = response.Data.announcement[0]
                console.log(data)
                setTitle(data.title)
                setContent(data.content)
                setPushDate(new Date(parseInt(data.send_time) * 1000).toLocaleString())
            }
        }).catch(error => {
            // console.error(error);
        });
    }

    return (
        <View style={styles.container}>
            <View style={styles.contentContainer}>
                <Text style={styles.title}>{title}</Text>
                <Text style={styles.content}>{pushDate}</Text>
                <Text style={styles.content}>{content}</Text>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: commonStyle.bgColor,
    },
    contentContainer: {
        width: '90%',
    },
    title: {
        color: '#fff',
        textAlign: 'center',
        fontSize: 18,
        marginBottom: 10,
    },
    content: {
        color: '#fff',
        fontSize: 16,
        marginBottom: 20
    },
});

export default MessageDetail;