import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TouchableOpacity, FlatList, ActivityIndicator, StyleSheet, RefreshControl } from 'react-native';
import { commonStyle } from '../components/style'
import { useTranslation } from 'react-i18next';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { getUserbalance, getTraderecord, getRechargeTron } from '../api/apis';
import Toast from 'react-native-easy-toast'
import AsyncStorage from '@react-native-community/async-storage';
import { unitconversion } from '../components/util'

const Balance = () => {

    const { t } = useTranslation();
    const navigation = useNavigation();
    const toastRef = useRef(null);
    const [rechargeData, setRechargeData] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [rechargePage, setRechargePage] = useState(1);
    const [balance, setBalance] = useState('');
    const [currency, setCurrency] = useState('USD');
    const [emptyShow, setEmptyShow] = useState(false);

    useEffect(() => {
        setRechargePage(1)
        traderecord()

        // setRefreshing(true);
        // setTimeout(() => {

        // }, 1000);
    }, []);

    useFocusEffect(
        React.useCallback(() => {
            userbalance()
        }, [])
    );

    // 刷新
    const handleRefresh = () => {
        userbalance()
        setRefreshing(true);
        setRechargePage(1)
        setTimeout(() => {
            traderecord()
        }, 1000);
    };

    // 加载更多数据
    const handleLoadMore = () => {
        // console.log('更多...', rechargePage)
        setRechargePage(parseInt(rechargePage) + 1)
        setLoadingMore(true);
        setTimeout(() => {
            traderecord()
        }, 1000);
    };

    //获取充值记录
    const traderecord = async () => {
        getTraderecord(rechargePage, 'cz').then(response => {
            setRefreshing(false);
            setLoadingMore(false);

            if (response.status == 1) {
                console.log(response.Data1.item)
                const updateData = response.Data1.item.map(data => {
                    let statusText = '';
                    switch (data.status) {
                        case '1':
                            statusText = t('processingPayment');
                            break;
                        case '2':
                            statusText = t('deposited');
                            break;
                        case '3':
                            statusText = t('cancelled');
                            break;
                        default:
                            statusText = '';
                    }
                    const addDate = new Date(parseInt(data.add_date)).toLocaleString();
                    const inMoney = (data.inMoney / 100).toFixed(2)
                    return { ...data, status: statusText, add_date: addDate, inMoney: inMoney };
                });

                const dataList = rechargeData.concat(updateData)

                console.log('记录')
                console.log(dataList)

                if (rechargePage > 1) {
                    setRechargeData(dataList)
                } else {
                    setRechargeData(updateData)
                    if (dataList.length == 0) {
                        setEmptyShow(true)
                    }
                }
            }
        }).catch(error => {
            setRefreshing(false);
            setLoadingMore(false);
            // console.error(error);
        });
    };

    // 获取余额
    const userbalance = async () => {
        getUserbalance().then(response => {
            if (response.status == 1 && response.Data.Code == '1') {
                setBalance(unitconversion(response.Data.BackData));
            }
        }).catch(error => {
            // console.error(error);
        });


        const l = await AsyncStorage.getItem('websiteInfo');
        const lj = JSON.parse(l);
        if (lj.Data.curr != '') {
            setCurrency(lj.Data.curr)
        }

    };

    //去充值
    const goToRechargeScreen = () => {
        getRechargeTron().then(response => {
            if (response.status == 1) {
                // response.item.RechargeWayBank.length = 0
                if (response.item.RechargeWayBank.length > 0) {
                    navigation.navigate('Deposit');
                } else {
                    toastRef.current.show(t('pleaseBindPaymentAccount'))
                    // navigation.navigate('PaymentMethod');
                    return
                }
            }

        }).catch(error => {
            console.log(error)
        })
    }

    const renderItem = ({ item }) => (
        <View style={styles.itemContainer}>
            <View style={styles.itemRowContainer}>
                <View><Text style={styles.itemTextGreen}>{item.types}</Text></View>
                <View><Text style={styles.itemTextGreen}>{item.status}</Text></View>
            </View>
            <View style={styles.itemRowContainer}>
                <View><Text style={styles.itemTextGray}>{item.add_date}</Text></View>
                <View>
                    <TouchableOpacity onPress={() => handleIconPress(item)}>
                        <Text style={styles.itemTextGray}>
                            {item.inMoney}
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );

    return (
        <View style={styles.container}>
            <View style={{ marginHorizontal: 10, height: '100%' }}>

                <View style={styles.containerHeader}>
                    <View style={[styles.center, { flexDirection: 'row', marginTop: 20 }]}>
                        <Text style={[styles.whileText, { fontSize: 25 }]}>{balance}</Text>
                        <View>
                            <Text style={[styles.itemTextGreen, { marginBottom: -5 }]}>({currency})</Text>
                        </View>
                    </View>
                    <TouchableOpacity onPress={goToRechargeScreen}>
                        <View style={styles.rechargeButton}>
                            <Text style={styles.whileText}>{t('recharge')}</Text>
                        </View>
                    </TouchableOpacity>
                </View>

                <View style={styles.tabContainer}>
                    <View style={styles.center}>
                        <Text style={styles.tabText}>{t('rechargeRecord')}</Text>
                    </View>
                </View>

                {emptyShow && <View style={{ justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                    <Text style={{ color: '#fff' }}>{t('noData')}</Text>
                </View>}
                {/* <Image style={styles.image} source={require('../../assets/imgs/common/empty.png')} /> */}

                <FlatList
                    data={rechargeData}
                    renderItem={renderItem}
                    keyExtractor={(item, index) => index}
                    refreshControl={<RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />}
                    onEndReached={!loadingMore && handleLoadMore}
                    onEndReachedThreshold={0.1}
                    ListFooterComponent={loadingMore && <ActivityIndicator color={commonStyle.green} />}
                />

            </View>

            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={200}
                textStyle={{ color: '#fff' }}
                fadeInDuration={750}
                fadeOutDuration={2000}
                opacity={0.8}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#1B1D29',
        // height: '99%'
    },
    tabContainer: {
        flexDirection: 'row',
        // paddingVertical: 10,
        backgroundColor: '#1B1D29',
        marginVertical: 10
    },
    tabItem: {
        flex: 1,
        alignItems: 'center',
        // paddingVertical: 10,
    },
    tabText: {
        color: commonStyle.textWhiteColor,
        fontSize: 17,
    },
    itemTextGreen: {
        color: commonStyle.textGreenColor,
    },
    itemTextGray: {
        color: commonStyle.textGrayColor
    },
    itemContainer: {
        // flexDirection: "column",
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderBottomWidth: 1,
        backgroundColor: commonStyle.black,
        marginTop: 10,
        // width: '90%',
        borderRadius: 5,
        // margin: 'auto'
    },
    itemRowContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 4
    },
    center: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    rechargeButton: {
        flexDirection: 'row',
        backgroundColor: commonStyle.green,
        justifyContent: 'center',
        alignItems: 'center',
        height: 35,
        // width: '25%',
        paddingHorizontal: 15,
        marginTop: 20,
        borderRadius: 20,
        // margin: 'auto',
        marginBottom: 20
    },
    whileText: {
        color: commonStyle.textWhiteColor
    },
    containerHeader: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: commonStyle.black,
        height: 140
    }
});

export default Balance;